import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getActiveSceneHeaderActionItems, getHeaderSize, isShowHeaderText, setHeaderSize } from 'scenes/scenesSlice';
import { SceneItem } from 'scenes/Scene.types';
import { MenuItem, MenuOnSelectEventT, SimpleMenu } from '@rmwc/menu';
import { IconButton } from '@rmwc/icon-button';
import { Icon } from '@rmwc/icon';
import { ListDivider } from '@rmwc/list';
import { IconSizeT, ThemePropT } from '@rmwc/types';
import { Typography } from '@rmwc/typography';

import Logo from 'components/logo/Logo';
import HeaderButton from './HeaderButton';
import { getUserStatus, isAnonymous, performUserLogout, setShowEditAccountForm, setShowLoginForm } from 'components/user/userSlice';

import styles from './Header.module.scss';

export interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  onNavEvent: () => void;
}

type SettingsMenuProps = {
  className?: string;
  headerSize?: IconSizeT;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
};

type HeaderMenuItemProps = {
  title: string;
  icon?: string;
  size?: IconSizeT;
  label?: string;
  rotate?: string;
  theme?: ThemePropT;
  show?: boolean;
};

const HeaderMenuItem: React.FC<HeaderMenuItemProps> = ({
  icon = 'warning',
  size = 'small',
  label = '',
  rotate = '0',
  theme = 'secondary',
  ...props
}: HeaderMenuItemProps) => {
  return (
    <MenuItem
      className={`${props.show === false ? styles.HeaderMenuItemHidden : ''}`}
      title={props.title}
      theme={theme}
    >
      <Icon className={styles.HeaderMenuItemIcon} icon={{ icon, size }} style={{ rotate: `${rotate}` }} />
      <Typography
        style={{ padding: '4px 8px', fontSize: '14px', minWidth: 'max-content' }}
        use="overline"
        theme={theme}
      >
        {label}
      </Typography>
    </MenuItem>
  );
};

const SettingsMenu: React.FC<SettingsMenuProps> = ({
  headerSize = 'small',
  onMenuOpen = () => {},
  onMenuClose = () => {},
  ...props
}) => {
  const userStatus = useAppSelector(getUserStatus);
  const isSignedIn = userStatus === 'signedIn';
  const isUserAnonymous = useAppSelector(isAnonymous);
  const dispatch = useAppDispatch();

  const handleClickAction = (evt: MenuOnSelectEventT) => {
    const { title } = evt.detail.item;

    if (title === 'HeaderSize') {
      dispatch(setHeaderSize(headerSize === 'large' ? 'small' : 'large'));
    } else if (title === 'UserAuth') {
      if (userStatus === 'signedIn') {
        dispatch(performUserLogout());
      } else if (userStatus === 'signedOut' || userStatus === 'idle') {
        dispatch(setShowLoginForm(true));
      }
    } else if (title === 'Edit') {
      dispatch(setShowEditAccountForm(true));
    }
  };

  const HeaderResizeName = headerSize === 'large' ? 'Show small header' : 'Show large header';
  const AuthIconName = isSignedIn ? 'logout' : 'login';
  return (
    <div className={styles.HeaderMenuTrigger}>
      <SimpleMenu
        anchorCorner="topStart"
        onOpen={onMenuOpen}
        onClose={onMenuClose}
        onSelect={handleClickAction}
        handle={<IconButton className={styles.HeaderMenuTriggerIcon} theme={'secondary'} icon="more_vert" />}
      >
        <HeaderMenuItem icon="manage_accounts" label="Edit Account" title="Edit" show={isSignedIn && !isUserAnonymous} />
        <HeaderMenuItem icon="resize" label={HeaderResizeName} title="HeaderSize" />
        <ListDivider />
        <HeaderMenuItem icon={AuthIconName} label={AuthIconName} title="UserAuth" theme={'error'} />
      </SimpleMenu>
    </div>
  );
};

const Header: React.FC<HeaderProps> = (_props: HeaderProps) => {
  const [headerZCls, setHeaderZCls] = useState<string>('');
  const headerActionItems: SceneItem[] = useAppSelector(getActiveSceneHeaderActionItems);
  const showTextOnItems: boolean = useAppSelector(isShowHeaderText);
  const headerSize = useAppSelector(getHeaderSize);

  console.log('Calling Header');

  const onMenuOpen = () => {
    setHeaderZCls(styles.HeaderIncreaseZ);
  };
  const onMenuClose = () => {
    setHeaderZCls('');
  };

  const renderHeaderActionItems = () => {
    return headerActionItems.map((item: SceneItem, index: number) => (
      <HeaderButton key={`header_item_${index}`} id={`item_${item.type}`} sceneItem={item} showText={showTextOnItems} />
    ));
  };

  return (
    <header className={`${styles.Header} ${headerZCls}`}>
      {headerSize === 'large' && <Logo />}
      <SettingsMenu
        className={styles.HeaderMenuTrigger}
        headerSize={headerSize}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
      />
      <nav className={styles.HeaderNavItems}>{renderHeaderActionItems()}</nav>
    </header>
  );
};

export default Header;
