import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { SceneItem, SceneType } from 'scenes/Scene.types';
import { isActiveScene } from 'scenes/scenesSlice';

import { Elevation } from '@rmwc/elevation';
import { Icon } from '@rmwc/icon';

import styles from './HeaderButton.module.scss';

export interface HeaderButtonProps {
  id: string;
  sceneItem: SceneItem;
  showText?: boolean;
}

const HeaderButton: React.FC<HeaderButtonProps> = ({ id, sceneItem, showText }: HeaderButtonProps) => {
  const [zElevation, setZElevation] = useState<number>(0);

  const isActive = useSelector((state: RootState) => isActiveScene(state, sceneItem.type));
  const isHomeScene = sceneItem.type === SceneType.Home;

  const toLink = isHomeScene ? '/' : sceneItem.type;

  return (
    <Elevation
      key={`e_${id}`}
      state={sceneItem.type}
      tag={NavLink}
      to={toLink}
      transition
      end={isHomeScene ? true : false}
      className={`${styles.HeaderIconButtonElevation} ${isActive ? 'active' : ''}`}
      z={isActive ? 7 : zElevation}
      onMouseOver={() => setZElevation(3)}
      onMouseOut={() => setZElevation(0)}
      onClick={() => setZElevation(0)}
    >
      {/* // ${isActive ? 'active' : ''}`}>  className={`${styles.HeaderIconButtonElevation}`} > */}
      <div key={id}> 
        <Icon icon={sceneItem.icon} />
        {showText ? sceneItem.type : ''}
      </div>
    </Elevation>
  );
};

export default HeaderButton;
