import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { createUserRecordOnUserAccountCreation, fetchUserInfo, updateUserAccount } from 'api/UserAPI';
import {
  auth,
  signInAnonymously,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
} from '../../firebase/Firebase';
import { UserCredential } from 'firebase/auth';

type UserStatusT = 'idle' | 'creatingAccount' | 'updatingAccount' | 'signingIn' | 'signedIn' | 'signingOut' | 'signedOut';

export interface IBaseUser {
  uid: string;
  firstname: string;
  lastname: string;
  email: string;
}

export interface IUser extends IBaseUser {
  isEditor: boolean;
}

export interface FBUser extends IBaseUser {
  editor: boolean;
}

export interface UserState extends IUser {
  isAnonymous: boolean;
  showLoginForm: boolean;
  loginFormError: string;
  createAccountFormError: string;
  editAccountFormError: string;
  showResetPasswordForm: boolean;
  showCreateAccountForm: boolean;
  showEditAccountForm: boolean;
  isAuthStateObserverInit: boolean;
  userStatus: UserStatusT;
}

const initialState: UserState = {
  uid: '',
  firstname: '',
  lastname: '',
  email: '',
  isEditor: false,
  isAnonymous: true,
  showLoginForm: false,
  loginFormError: '',
  createAccountFormError: '',
  editAccountFormError: '',
  showResetPasswordForm: false,
  showCreateAccountForm: false,
  showEditAccountForm: false,
  isAuthStateObserverInit: false,
  userStatus: 'idle',
};

export const performUserLogout = (): AppThunk => (dispatch) => {
  console.log('performUserLogout');
  dispatch(setUserStatus('signingOut'));
  signOut(auth)
    .then(() => {
      dispatch(setUserStatus('signedOut'));
    })
    .catch((reason) => {
      console.log(`something happened during performUserLogout action, ${reason}`);
      dispatch(setUserStatus('idle'));
    });
};

export const displayCreateAccountForm = (): AppThunk => (dispatch) => {
  console.log('displayCreateAccountForm');
  dispatch(setShowLoginForm(false));
  dispatch(setShowCreateAccountForm(true));
};

export const performCreateAccount =
  (email: string, password: string): AppThunk =>
  (dispatch) => {
    dispatch(setUserStatus('creatingAccount'));
    dispatch(setCreateAccountFormError(''));
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userModel: UserCredential) => {
        await createUserRecordOnUserAccountCreation(userModel.user);
        dispatch(setShowCreateAccountForm(false));
      })
      .catch((reason) => {
        console.log(`something happened during performCreateAccount action, ${reason}`);
        dispatch(setCreateAccountFormError(reason.code));
        dispatch(setUserStatus('idle'));
      });
  };

  export const doUpdateUserAccount =
  (user: IUser): AppThunk =>
  async (dispatch, getState) => {
    const {uid, isEditor} = getState().user;
    const isOwnUser = user.uid === uid;

    if (isOwnUser || (uid && isEditor)) {
      dispatch(setUserStatus('updatingAccount'));
      dispatch(setEditAccountFormError(''));
      const updatedUser: IUser = await updateUserAccount('uid', user);
      dispatch(setUserInfo(updatedUser));
      dispatch(setUserStatus('signedIn'));
      dispatch(setShowEditAccountForm(false));
    } else {
      dispatch(setEditAccountFormError('Cannot edit user'));
    }
  };

export const performEmailPasswordUserLogin =
  (email: string, password: string): AppThunk =>
  (dispatch) => {
    dispatch(setUserStatus('signingIn'));
    dispatch(setLoginFormError(''));
    signInWithEmailAndPassword(auth, email, password)
      .then((userModel) => {
        dispatch(setShowLoginForm(false));
      })
      .catch((reason) => {
        console.log(`something happened during performEmailPasswordUserLogin action, ${reason}`);
        dispatch(setLoginFormError(reason.code));
        dispatch(setUserStatus('idle'));
      });
  };

export const performAnonymousUserLogin = (): AppThunk => (dispatch) => {
  dispatch(setUserStatus('signingIn'));
  dispatch(setLoginFormError(''));
  signInAnonymously(auth)
    .then((userModel) => {
      dispatch(setShowLoginForm(false));
    })
    .catch((reason) => {
      console.log(`something happened during performAnonymousLogin action, ${reason}`);
      dispatch(setLoginFormError(reason.code));
      dispatch(setUserStatus('idle'));
    });
};

export const updateUserStatus = (): AppThunk => (dispatch, getState) => {
  const isInit = getState().user.isAuthStateObserverInit;

  if (!isInit) {
    console.log(`userSlice | observe onAuthStateChanged event`);
    auth.onAuthStateChanged(async (userModel) => {
      dispatch(setAuthStateObserverInit(true));
      if (userModel) {
        dispatch(setUserStatus('signedIn'));
        dispatch(setUserId(userModel.uid));
        dispatch(setAnonymous(userModel.isAnonymous));
        // dispatch(setCanUserEdit(userModel.uid.length > 0 && !userModel.isAnonymous));
        const userInfo: IUser = await fetchUserInfo(userModel.uid);
        dispatch(setUserInfo(userInfo));
      } else {
        dispatch(setCanUserEdit(false));
        dispatch(resetState());
      }
    });
  }
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<IUser>) => {
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.email = action.payload.email;
      state.isEditor = action.payload.isEditor;
      state.uid = action.payload.uid;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.uid = action.payload;
    },
    setAnonymous: (state, action: PayloadAction<boolean>) => {
      state.isAnonymous = action.payload;
    },
    setShowLoginForm: (state, action: PayloadAction<boolean>) => {
      state.showLoginForm = action.payload;
    },
    setShowResetPasswordForm: (state, action: PayloadAction<boolean>) => {
      state.showResetPasswordForm = action.payload;
    },
    setShowCreateAccountForm: (state, action: PayloadAction<boolean>) => {
      state.showCreateAccountForm = action.payload;
    },
    setShowEditAccountForm: (state, action: PayloadAction<boolean>) => {
      state.showEditAccountForm = action.payload;
    },
    setAuthStateObserverInit: (state, action: PayloadAction<boolean>) => {
      state.isAuthStateObserverInit = action.payload;
    },
    setUserStatus: (state, action: PayloadAction<UserStatusT>) => {
      state.userStatus = action.payload;
    },
    setLoginFormError: (state, action: PayloadAction<string>) => {
      state.loginFormError = action.payload;
    },
    setCreateAccountFormError: (state, action: PayloadAction<string>) => {
      state.createAccountFormError = action.payload;
    },
    setEditAccountFormError: (state, action: PayloadAction<string>) => {
      state.editAccountFormError = action.payload;
    },
    setCanUserEdit: (state, action: PayloadAction<boolean>) => {
      state.isEditor = action.payload;
    },
    resetState: (state) => {
      return { ...initialState, isAuthStateObserverInit: state.isAuthStateObserverInit };
    },
  },
});

export const {
  setUserInfo,
  setUserId,
  setAnonymous,
  setShowCreateAccountForm,
  setShowResetPasswordForm,
  setShowLoginForm,
  setShowEditAccountForm,
  setAuthStateObserverInit,
  setUserStatus,
  setLoginFormError,
  setCreateAccountFormError,
  setEditAccountFormError,
  setCanUserEdit,
  resetState,
} = userSlice.actions;

export const getUserId = (state: RootState): string => state.user.uid;
export const getUser = (state: RootState): IUser => state.user;
export const isAnonymous = (state: RootState): boolean => state.user.isAnonymous;
export const isAuthStateObserverInit = (state: RootState): boolean => state.user.isAuthStateObserverInit;
export const showCreateAccountForm = (state: RootState): boolean => state.user.showCreateAccountForm;
export const showResetPasswordForm = (state: RootState): boolean => state.user.showResetPasswordForm;
export const showEditAccountForm = (state: RootState): boolean => state.user.showEditAccountForm;
export const showLoginForm = (state: RootState): boolean => state.user.showLoginForm;
export const getUserStatus = (state: RootState): string => state.user.userStatus;
export const getLoginFormError = (state: RootState): string => state.user.loginFormError;
export const getCreateAccountFormError = (state: RootState): string => state.user.createAccountFormError;
export const getEditAccountFormError = (state: RootState): string => state.user.editAccountFormError;
export const canUserEdit = (state: RootState) => state.user.isEditor;

export default userSlice.reducer;
