import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  getCreateAccountFormError,
  performCreateAccount,
  setCreateAccountFormError,
  setShowCreateAccountForm,
  showCreateAccountForm,
} from 'components/user/userSlice';
import { DialogButton } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import BaseDialog from '../BaseDialog';
import BaseInputField from 'components/inputfield/BaseInputField';

import styles from './CreateUserDialog.module.scss';

const CreateUserDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(showCreateAccountForm);
  const errorText = useAppSelector(getCreateAccountFormError);
  const onSetOpen = (shouldOpen: boolean) => dispatch(setShowCreateAccountForm(shouldOpen));

  const [username, setUsernameField] = useState<string>('');
  const [password, setPasswordField] = useState<string>('');

  const setUsername = (val: string) => setUsernameField(val.trim());
  const setPassword = (val: string) => setPasswordField(val.trim());

  const resetFields = () => {
    setUsernameField('');
    setPasswordField('');
    dispatch(setCreateAccountFormError(''));
  };

  const actionHandler = (actionName: string) => {
    if (actionName === 'create') {
      dispatch(performCreateAccount(username, password));
    }
  };

  const dialogContent = (
    <div className={styles.CreateUserDialogContent}>
      <BaseInputField
        id={'createEmailId'}
        type={'text'}
        label={'Email'}
        value={username.trim()}
        onChange={setUsername}
      />
      <BaseInputField
        id={'createPwdId'}
        type={'password'}
        label={'Password'}
        value={password.trim()}
        onChange={setPassword}
      />
      <Typography className={styles.CreateUserDialogError} tag="div" use="body2">
        {errorText}
      </Typography>
    </div>
  );

  const dialogActions = (
    <div className={styles.CreateUserDialogActionButtons}>
      <DialogButton theme="secondary" dense action="close" label="Cancel" />

      <DialogButton theme="secondaryBg" raised onClick={() => actionHandler('create')} isDefaultAction label="Create" />
    </div>
  );

  return (
    <BaseDialog
      open={isOpen}
      preventOutsideDismiss={true}
      setOpen={onSetOpen}
      onActionCallback={actionHandler}
      onClosedCallback={resetFields}
      title={'Create Account'}
      content={dialogContent}
      actions={dialogActions}
    />
  );
};

export default CreateUserDialog;
