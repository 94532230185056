import { Dictionary, SceneItem, SceneType } from './Scene.types';

export const SceneHome: SceneItem = {
  type: SceneType.Home,
  icon: 'home',
  title: 'Home',
  drawerIcon: 'home',
  onIcon: 'home',
  onDrawerIcon: 'home',
  active: true,
  header: {
    title: '{AS} Portfolio',
    navItem: SceneType.Home,
    actionItems: [
      SceneType.Home,
      SceneType.Projects,
      SceneType.Contact,
      SceneType.AboutUs,
    ],
  },
};

export const SceneProjects: SceneItem = {
  type: SceneType.Projects,
  icon: 'code',
  title: 'Projects',
  drawerIcon: 'code',
  onIcon: 'code',
  onDrawerIcon: 'code',
  active: true,
  header: {
    title: 'Projects',
    navItem: SceneType.Projects,
    actionItems: [
      SceneType.Home,
      SceneType.Projects,
      SceneType.Contact,
      SceneType.AboutUs,
    ],
  },
};

export const SceneSkills: SceneItem = {
  type: SceneType.Skills,
  icon: 'tools_wrench',
  title: 'Skills',
  drawerIcon: 'tools_wrench',
  onIcon: 'tools_wrench',
  onDrawerIcon: 'tools_wrench',
  active: false,
  header: {
    title: 'Skills',
    navItem: SceneType.Home,
    actionItems: [
      SceneType.Home,
      SceneType.Projects,
      SceneType.Contact,
      SceneType.AboutUs,
    ],
  },
};

export const SceneSchool: SceneItem = {
  type: SceneType.School,
  icon: 'history_edu',
  title: 'Education',
  drawerIcon: 'history_edu',
  onIcon: 'history_edu',
  onDrawerIcon: 'history_edu',
  active: false,
  header: {
    title: 'School',
    navItem: SceneType.Home,
    actionItems: [
      SceneType.Home,
      SceneType.Projects,
      SceneType.Contact,
      SceneType.AboutUs,
    ],
  },
};

export const SceneEmployment: SceneItem = {
  type: SceneType.Employment,
  icon: 'work_history',
  title: 'Employment',
  drawerIcon: 'work_history',
  onIcon: 'work_history',
  onDrawerIcon: 'work_history',
  active: false,
  header: {
    title: 'Employment',
    navItem: SceneType.Home,
    actionItems: [
      SceneType.Home,
      SceneType.Projects,
      SceneType.Contact,
      SceneType.AboutUs,
    ],
  },
};

export const SceneContact: SceneItem = {
  type: SceneType.Contact,
  icon: 'mail',
  title: 'Contact',
  drawerIcon: 'mail',
  onIcon: 'mail',
  onDrawerIcon: 'mail',
  active: true,
  header: {
    title: '{AS} Portfolio',
    navItem: SceneType.Contact,
    actionItems: [SceneType.Home, SceneType.Projects, SceneType.Contact, SceneType.AboutUs],
  },
};

export const SceneAboutUs: SceneItem = {
  type: SceneType.AboutUs,
  icon: 'emoji_people',
  title: 'About Me',
  drawerIcon: 'emoji_people',
  onIcon: 'emoji_people',
  onDrawerIcon: 'emoji_people',
  active: true,
  header: {
    title: '{AS} Portfolio',
    navItem: SceneType.AboutUs,
    actionItems: [
      SceneType.Home,
      SceneType.Projects,
      SceneType.Contact,
      SceneType.AboutUs,
    ],
  },
};

export const allScenes = (): Dictionary<SceneItem> => {
  let scenes: Dictionary<SceneItem> = {};
  scenes[SceneHome.type] = SceneHome;
  scenes[SceneProjects.type] = SceneProjects;
  scenes[SceneContact.type] = SceneContact;
  scenes[SceneAboutUs.type] = SceneAboutUs;
  return scenes;
};

export const getDrawerItems = (): SceneType[] => {
  return [SceneHome.type, SceneProjects.type];
};

export const isEmptyString = (text: string): boolean => text === undefined || text === null || text === '';
