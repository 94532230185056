import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from 'app/store';
import { RMWCProvider } from '@rmwc/provider';
import * as serviceWorker from './serviceWorker';

import 'rmwc/dist/styles';
import './index.scss';

import router from 'Router';


let persistor = persistStore(store);
persistor.purge();

const container: HTMLElement | null = document.getElementById('root');

if (container === null) {
  throw Error(`root not found`);
}

const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <Provider
    store={store}
    children={
      <PersistGate loading={null} persistor={persistor}>
        <RMWCProvider
          ripple={false}
          // icon={{ basename: 'material-symbols-outlined' }}
          icon={{ basename: 'material-symbols-rounded' }}
          typography={{ defaultTag: 'div', headline1: 'h1' }}
        >
          <RouterProvider router={router} />
        </RMWCProvider>
      </PersistGate>
    }
  ></Provider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
