import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import scenesReducer from 'scenes/scenesSlice';
import projectsReducer from 'scenes/project/projectsSlice';
import aboutusReducer from 'scenes/aboutus/aboutUsSlice';
import pillsReducer from 'components/pill/pillsListSlice';
import contactSceneReducer from 'scenes/contact/contactSceneSlice';
import userReducer from 'components/user/userSlice';
import activeReducer from  'scenes/aboutus/activeSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const reducers = combineReducers({
  categories: pillsReducer,
  contactscene: contactSceneReducer,
  scenes: scenesReducer,
  projects: projectsReducer,
  user: userReducer,
  aboutus: aboutusReducer,
  active: activeReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
