import React, { ReactNode } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Snackbar, SnackbarAction } from '@rmwc/snackbar';
import { getSnackbarMessage, isSnackbarOpen, setSnackbarOpen, getSnackbarType } from 'scenes/scenesSlice';

import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';

import styles from './NotificationSnackbar.module.scss';

const formatCategoryName = (categoryName: string) => {
  const catLength = categoryName.length - 1;
  return categoryName.charAt(catLength) === 's' ? categoryName.slice(0, catLength) : categoryName;
};

type NotificationParams = {
  name: string;
  item: string;
  category: string;
};

type MsgParams = {
  //icon: string; // <icon_tag>, <icon_skill>, <icon_project>
  iconType: string; // tag, skill
  action: string; // Added, Updated, Created, Removed
  actionTxt: string; // to, '', '', from
} & NotificationParams;

const getAddRemoveMessageTpl = ({ name, item, category, iconType, action, actionTxt }: MsgParams): string => {
  const labelName = name.toUpperCase();
  const itemName = item.toUpperCase();
  let categoryName = '';
  if (category !== 'education' && category !== 'employment') {
    categoryName = formatCategoryName(category);
  }
  const actionPosfix = `${actionTxt}|`;

  return `${action}|<icon_${iconType}>|<b>${labelName}|${iconType}|${actionPosfix}${categoryName}|<b>${itemName}`;
};

const getCreatedUpdatedMessageTpl = ({ name, item, category, iconType, action, actionTxt }: MsgParams): string => {
  const labelName = name.toUpperCase();
  const categoryName = formatCategoryName(category);
  const actionText = category !== 'category' ? actionTxt : '';

  return `${action}|<icon_${item}>|<b>${labelName}|${categoryName}|${actionText}`;
};
const getCreatedItemNotification = (props: NotificationParams): string => {
  return getCreatedUpdatedMessageTpl({ ...props, iconType: props.item, action: 'Created', actionTxt: '' });
};
const getUpdatedItemNotification = (props: NotificationParams): string => {
  return getCreatedUpdatedMessageTpl({ ...props, iconType: props.item, action: 'Updated', actionTxt: 'info' });
};
const getAddedTagNotification = (props: NotificationParams): string => {
  return getAddRemoveMessageTpl({ ...props, iconType: 'tag', action: 'Added', actionTxt: 'to' });
};
const getRemovedTagNotification = (props: NotificationParams): string => {
  return getAddRemoveMessageTpl({ ...props, iconType: 'tag', action: 'Removed', actionTxt: 'from' });
};
const getAddedSkillNotification = (props: NotificationParams): string => {
  return getAddRemoveMessageTpl({ ...props, iconType: 'skill', action: 'Added', actionTxt: 'to' });
};
const getRemovedSkillNotification = (props: NotificationParams): string => {
  return getAddRemoveMessageTpl({ ...props, iconType: 'skill', action: 'Removed', actionTxt: 'from' });
};

const formatNotificationMessage = (message: string): ReactNode => {
  const messageTokens = message.split('|');
  if (messageTokens.length === 1) {
    return <Typography use="body2" className={styles.NSDisplay} children={message} />;
  }
  return (
    <Typography use="body2" className={styles.NSDisplay}>
      {messageTokens.map((token, index) => {
        const uniqueKey = `msg_${index}`;
        let msgItem = <React.Fragment>{token}</React.Fragment>;
        if (token === '<icon_tag>') {
          msgItem = <Icon key={uniqueKey} icon={{ icon: 'sell', size: 'small' }} />;
        } else if (token === '<icon_skill>') {
          msgItem = <Icon key={uniqueKey} icon={{ icon: 'build_circle', size: 'small' }} />;
        } else if (token === '<icon_lang>') {
          msgItem = <Icon key={uniqueKey} icon={{ icon: 'language', size: 'small' }} />;
        } else if (token === '<icon_xinfo>') {
          msgItem = <Icon key={uniqueKey} icon={{ icon: 'interests', size: 'small' }} />;
        } else if (token === '<icon_project>') {
          msgItem = <Icon key={uniqueKey} icon={{ icon: 'apps', size: 'small' }} />;
        } else if (token === '<icon_work>') {
          msgItem = <Icon key={uniqueKey} icon={{ icon: 'engineering', size: 'small' }} />;
        } else if (token === '<icon_school>') {
          msgItem = <Icon key={uniqueKey} icon={{ icon: 'school', size: 'small' }} />;
        } else if (token === '<icon_portfolio>') {
          msgItem = <Icon key={uniqueKey} icon={{ icon: 'cases', size: 'small' }} />;  
        } else if (token.indexOf('<b>') === 0) {
          msgItem = <b>{token.replace('<b>', '')}</b>;
        }
        return (
          <React.Fragment key={uniqueKey}>
            {msgItem}
            {token && <>&nbsp;</>}
          </React.Fragment>
        );
      })}
    </Typography>
  );
};

const NotificationSnackbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(isSnackbarOpen);
  const message = useAppSelector(getSnackbarMessage);
  const type = useAppSelector(getSnackbarType);

  const closeSnackbar = () => dispatch(setSnackbarOpen(false));

  return (
    <Snackbar
      open={open}
      className={styles[`NSDisplay--${type}`]}
      onClose={closeSnackbar}
      message={formatNotificationMessage(message)}
      dismissesOnAction
      action={<SnackbarAction label="Dismiss" onClick={closeSnackbar} />}
    />
  );
};

export {
  NotificationSnackbar as default,
  getAddedTagNotification,
  getRemovedTagNotification,
  getAddedSkillNotification,
  getRemovedSkillNotification,
  getCreatedItemNotification,
  getUpdatedItemNotification,
};
