const FirebaseConfig = {
  apiKey: "AIzaSyCqZgXAwvyD4-BIpBxRsppc465xidbMxQE",
  authDomain: "eleventheye-20fcf.firebaseapp.com",
  projectId: "eleventheye-20fcf",
  storageBucket: "eleventheye-20fcf.appspot.com",
  messagingSenderId: "772259166620",
  appId: "1:772259166620:web:ae02dfd11b64197f14b059",
  measurementId: "G-T6H73GSTMY",
};

export { FirebaseConfig as default };
