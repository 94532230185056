import React, { useEffect } from 'react';
import { Typography } from '@rmwc/typography';
import Quote from 'components/quote/Quote';

import styles from './MainDisplay.module.scss';

const quoteString = '“Anyone who stops learning is old, whether he’s twenty or eighty.”';
const author = 'Henry Ford';
const citeUrl = 'https://teambuilding.com/blog/inspirational-work-quotes';
const whatIsEleventheye = 'What is eleventheye?';
const whatIsDescription = 'Eleventheye is a web software development company specializing in developing web solutions.';
const philosophyEleventheye = 'eleventheye philosophy';
const philosophyDescription =
  'Software today is rarely built once and forgotten, the applications are always evolving, and in need of new functionality. Thus, we build applications that are scalable, maintainable, and measured through data analysis to achieve the best outcome for our customers.';
const historyEleventheye = 'History of eleventheye';
const historyDescription =
  'Eleventheye started as a software development playground by Andrey Soubbotin when he was still a student at the University of Victoria. The goal was to explore, learn, and experiment with different technolgies. Over the years the site has transformed into a presentation portal for eleventheye technologies.';

const MainDisplay: React.FC = () => {
  console.log('Home | Main display');

  useEffect(() => {
    document.title = `eleventheye`;
  }, []);

  return (
    <div className={styles.MainDisplayBody}>
      <Quote cite={citeUrl} author={author} quote={quoteString} />
      <div className={styles.MainDisplayQandA}>
        <div className={styles.MainDisplaySection}>
          <Typography use="overline" theme="onPrimary">
            {whatIsEleventheye}
          </Typography>
          <Typography use="body2" theme="onSecondary">
            {whatIsDescription}
          </Typography>
        </div>
        <div className={styles.MainDisplaySection}>
          <Typography use="overline" theme="onPrimary">
            {philosophyEleventheye}
          </Typography>
          <Typography use="body2" theme="onSecondary">
            {philosophyDescription}
          </Typography>
        </div>
        <div className={styles.MainDisplaySection}>
          <Typography use="overline" theme="onPrimary">
            {historyEleventheye}
          </Typography>
          <Typography use="body2" theme="onSecondary">
            {historyDescription}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default MainDisplay;
