import { db } from '../firebase/Firebase';
import { getDocs, collection, addDoc, orderBy, query, updateDoc, doc, serverTimestamp } from 'firebase/firestore';
import { ITag } from 'scenes/aboutus/AboutUs.types';

export const fetchTags = async (): Promise<Array<ITag>> => {
  const q = query(collection(db, 'tags'), orderBy('name', 'asc'));
  const querySnapshot = await getDocs(q);
  // console.log(`fetched tags count: ${querySnapshot.size}`);
  let tagsList: Array<ITag> = [];
  querySnapshot.docs.forEach((doc) => {
    const docData = doc.data();
    tagsList.push({ name: docData.name, id: doc.id });
  });

  return tagsList;
};

export const createTag = async (name: string, userUid: string): Promise<ITag> => {
  const newTag = {
    name: name.toLowerCase(),
    createdBy: userUid,
    createdAt: serverTimestamp(),
  };
  const docRef = await addDoc(collection(db, 'tags'), newTag);
  console.log(`Document written with ID:  ${docRef.id}, user: ${userUid}`);
  return { id: docRef.id, name: name, createdBy: newTag.createdBy };
};

export const updateTag = async (tag: ITag, userUid: string): Promise<ITag> => {
  const tagRef = doc(db, `tags/${tag.id}`);
  await updateDoc(tagRef, { name: tag.name.toLowerCase(), modifiedBy: userUid, modifiedAt: serverTimestamp() });
  return { ...tag };
};
