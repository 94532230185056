import { db } from '../firebase/Firebase';
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import {
  ISkill,
} from 'scenes/aboutus/AboutUs.types';

export const loadSkillsData = async (portfolioId: string): Promise<Array<ISkill>> => {
  let skillItems: Array<ISkill> = [];
  const q = query(collection(db, `portfolio/${portfolioId}/skills`), orderBy('name', 'asc'));
  const skillsSnapshot = await getDocs(q);
  skillsSnapshot.docs.forEach((doc) => {
    const docData = doc.data();
    skillItems.push({
      id: doc.id,
      name: docData.name,
      description: docData.description,
      includeInSkillsHighlight: !!docData.includeInSkillsHighlight,
      level: docData.level,
      tags: docData.tags,
    });
  });
  return skillItems;
};

export const createSkill = async (
  portfolioId: string,
  skillName: string,
  skillDescription: string,
  skillLevel: string,
  includeInSkillsHighlight: boolean,
  userUid: string
): Promise<ISkill> => {
  let skillToAdd = {
    name: skillName,
    description: skillDescription,
    level: skillLevel,
    includeInSkillsHighlight: includeInSkillsHighlight,
    createdBy: userUid,
    createdAt: serverTimestamp(),
  };

  const docRef = await addDoc(collection(db, `portfolio/${portfolioId}/skills`), skillToAdd);
  console.log('Document written with ID: ', docRef.id);
  return { id: docRef.id, ...skillToAdd, tags: [], createdAt: skillToAdd.createdAt.toString() };
};

export const updateSkill = async (
  portfolioId: string,
  skillId: string,
  skillName: string,
  skillDescription: string,
  skillLevel: string,
  includeInSkillsHighlight: boolean,
  userUid: string
): Promise<ISkill> => {
  let skillToAdd = {
    name: skillName,
    description: skillDescription,
    level: skillLevel,
    createdBy: userUid,
    includeInSkillsHighlight: includeInSkillsHighlight,
    createdAt: serverTimestamp(),
  };

  const skillRef = doc(db, `portfolio/${portfolioId}/skills/${skillId}`);
  await updateDoc(skillRef, skillToAdd);
  return { ...skillToAdd, id: skillId, createdAt: skillToAdd.createdAt.toString() };
};

export const addTagToSkill = async (
  portfolioId: string,
  skillId: string,
  tagId: string,
  userUid: string
): Promise<Array<ISkill>> => {
  const skillRef = doc(db, `portfolio/${portfolioId}/skills/${skillId}`);
  await updateDoc(skillRef, {
    tags: arrayUnion(tagId),
    modifiedBy: userUid,
    modifiedAt: serverTimestamp(),
  });
  const skills = await loadSkillsData(portfolioId);

  return skills;
};

export const removeTagFromSkill = async (portfolioId: string, skillId: string, tagId: string, userUid: string) => {
  const skillRef = doc(db, `portfolio/${portfolioId}/skills/${skillId}`);
  await updateDoc(skillRef, {
    tags: arrayRemove(tagId),
    modifiedBy: userUid,
    modifiedAt: serverTimestamp(),
  });
  const skills = await loadSkillsData(portfolioId);

  return skills;
};

