import React from 'react';
import { useAppSelector } from 'app/hooks';
import { getUserId, isAnonymous } from './userSlice';
import LoginDialog from 'components/dialogs/user/LoginDialog';
import CreateUserDialog from 'components/dialogs/user/CreateUserDialog';
import EditUserDialog from 'components/dialogs/user/EditUserDialog';

const User: React.FC = () => {
  const userProfile = useAppSelector(getUserId);
  const isUserAnon = useAppSelector(isAnonymous);
  console.log(`uid: (${userProfile}), isAnon: ${isUserAnon}`);
  return <>
    <LoginDialog />
    <CreateUserDialog />
    <EditUserDialog />
  </>;
};

export default User;
