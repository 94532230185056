import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { IProject } from './Projects.types';

export interface ProjectsState {
  projects: Array<IProject>;
  isLoaded: boolean;
}

const initialState: ProjectsState = {
  projects: [],
  isLoaded: false,
};

// export const fetchProjects =
//   (append?: boolean): AppThunk =>
//   async (dispatch, getState) => {
//     const loaded = getState().projects.isLoaded;
//     if (loaded) {
//       dispatch(setLoaded(false));
//     }
//     const querySnapshot = await getDocs(collection(db, 'projects'));
//     const numProjects = querySnapshot.size;
//     querySnapshot.docChanges().forEach((docChange, index) => {
//       const doc = docChange.doc;
//       console.log(`${doc.id} => ${doc.data().projectName} count: ${numProjects - index}`);
//       const docData = doc.data();
//       const projectIconRef = ref(storage, docData.projectIcon + '.' + docData.iconExt);
//       getDownloadURL(projectIconRef)
//         .then((projectIconUrl: string) => {
//           dispatch(
//             addProject({
//               id: doc.id,
//               name: docData.projectName,
//               icon: projectIconUrl,
//               description: docData.projectDescription,
//               url: docData.projectUrl,
//             })
//           );
//         })
//         .catch((reason) => {
//           console.log(`Cannot get project icon url ${reason}`);
//           dispatch(
//             addProject({
//               id: doc.id,
//               name: docData.projectName,
//               icon: '/images/logo.webp',
//               description: docData.projectDescription,
//               url: docData.projectUrl,
//             })
//           );
//         })
//         .finally(() => {
//           if (numProjects === index + 1) {
//             dispatch(setLoaded(true));
//           }
//         });
//     });

//     console.log('fetchProjectData = loading data from cloud firestore');
//   };

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<IProject[]>) => {
      state.projects = action.payload;
    },
    addProject: (state, action: PayloadAction<IProject>) => {
      state.projects.push(action.payload);
      state.projects.sort((a, b) => (a.name > b.name ? 1 : a.name === b.name ? 0 : -1));
    },
    setLoaded: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload;
    },
  },
});

export const { setProjects, addProject, setLoaded } = projectsSlice.actions;

// The function below is called a selector and allows us to select
// a selected tab from the state.
export const getProjects = (state: RootState): IProject[] => state.projects.projects;
export const isProjectLoadedById = (state: RootState, id: string): boolean => {
  if (projectsLoaded(state)) {
    return getProjects(state).findIndex((project: IProject) => project.id === id) > -1;
  }
  return false;
};
export const getProjectById = (state: RootState, id: string) => {
  const loaded = projectsLoaded(state);
  if (loaded) {
    return getProjects(state).find((project) => project.id === id) || false;
  }
  return false;
};
export const getProject = (state: RootState, id: string) => {
  return getProjects(state).find((project) => project.id === id);
};

export const projectsLoaded = (state: RootState): boolean => state.projects.isLoaded;

export default projectsSlice.reducer;
