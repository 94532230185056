import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  canUserEdit,
  doUpdateUserAccount,
  getEditAccountFormError,
  getUser,
  setEditAccountFormError,
  setShowEditAccountForm,
  showEditAccountForm,
} from 'components/user/userSlice';
import { DialogButton } from '@rmwc/dialog';
import { Switch } from '@rmwc/switch';
import { Typography } from '@rmwc/typography';
import BaseDialog from '../BaseDialog';
import BaseInputField from 'components/inputfield/BaseInputField';

import styles from './CreateUserDialog.module.scss';

const EditUserDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(showEditAccountForm);
  const errorText = useAppSelector(getEditAccountFormError);
  const user = useAppSelector(getUser);
  const isEditor = useAppSelector(canUserEdit);
  const onSetOpen = (shouldOpen: boolean) => dispatch(setShowEditAccountForm(shouldOpen));

  const [username, setUsernameField] = useState<string>('');
  const [firstname, setFirstnameField] = useState<string>('');
  const [lastname, setLastnameField] = useState<string>('');
  const [editor, setEditor] = useState<boolean>(false);

  const setUsername = (val: string) => setUsernameField(val.trim());
  const setFirstname = (val: string) => setFirstnameField(val.trim());
  const setLastname = (val: string) => setLastnameField(val.trim());

  useEffect(() => {
    if (user) {
      setUsername(user.email);
      setFirstname(user.firstname);
      setLastname(user.lastname);
      setEditor(user.isEditor);
    }
  }, [user]);

  const resetFields = () => {
    setUsernameField('');
    setFirstnameField('');
    setLastnameField('');
    dispatch(setEditAccountFormError(''));
  };

  const actionHandler = (actionName: string) => {
    if (actionName === 'create') {
      dispatch(doUpdateUserAccount({ ...user, firstname, lastname }));
    }
  };

  const dialogContent = (
    <div className={styles.CreateUserDialogContent}>
      <BaseInputField
        id={'editEmailId'}
        type={'text'}
        label={'Email'}
        value={username.trim()}
        onChange={setUsername}
        disabled
      />
      <BaseInputField
        id={'editFirstname'}
        type={'text'}
        label={'Firstname'}
        value={firstname.trim()}
        onChange={setFirstname}
      />
      <BaseInputField
        id={'editLastname'}
        type={'text'}
        label={'Lastname'}
        value={lastname.trim()}
        onChange={setLastname}
      />
      {isEditor && (
        <Switch checked={editor} onChange={(evt: any) => setEditor(!!evt.currentTarget.checked)}>
          <Typography use="overline" theme="onPrimary">
            Editor
          </Typography>
        </Switch>
      )}
      <Typography className={styles.CreateUserDialogError} tag="div" use="body2">
        {errorText}
      </Typography>
    </div>
  );

  const dialogActions = (
    <div className={styles.CreateUserDialogActionButtons}>
      <DialogButton theme="secondary" dense action="close" label="Cancel" />

      <DialogButton theme="secondaryBg" raised onClick={() => actionHandler('create')} isDefaultAction label="Update" />
    </div>
  );

  return (
    <BaseDialog
      open={isOpen}
      preventOutsideDismiss={true}
      setOpen={onSetOpen}
      onActionCallback={actionHandler}
      onClosedCallback={resetFields}
      title={'Edit Account'}
      content={dialogContent}
      actions={dialogActions}
    />
  );
};

export default EditUserDialog;
