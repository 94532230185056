import { db } from '../firebase/Firebase';
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  getDocs,
  orderBy,
  query,
} from 'firebase/firestore';
import { IProject } from 'scenes/project/Projects.types';

export const loadProjects = async (): Promise<Array<IProject>> => {
  const q = query(collection(db, 'projects'), orderBy('projectName', 'asc'));
  const querySnapshot = await getDocs(q);
  let projects: Array<IProject> = [];

  querySnapshot.docs.forEach((doc) => {
    const docData = doc.data();
    projects.push({
      id: doc.id,
      name: docData.projectName,
      icon: docData.projectLogo,
      description: docData.projectDescription,
      url: docData.projectUrl,
    });
  });

  return projects;
};

export const loadProject = async (projectId: string): Promise<IProject> => {
  const docRef: DocumentReference<DocumentData> = doc(db, `projects/${projectId}`);
  const docSnap: DocumentSnapshot<DocumentData> = await getDoc(docRef);

  if (docSnap.exists()) {
    let project: IProject;

    const docData = docSnap.data();
    project = {
      id: projectId,
      name: docData.projectName,
      icon: docData.projectLogo,
      description: docData.projectDescription,
      url: docData.projectUrl,
    };
    return project; 
  } else {
    console.error('No such document!');
    throw Error(`Project does not exist for Path=projects/${projectId}`);
  }
};
