import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResultItemT } from 'App.types';
import { AppThunk, RootState } from 'app/store';
import { FirebaseError } from 'firebase/app';
import { analytics, logEvent } from '../../firebase/Firebase';
import { isEmptyString } from 'scenes/Scene.helpers';
import { setSnackbarError, setSnackbarSuccess } from 'scenes/scenesSlice';
import {
  IEducation,
  IPerson,
  IPersonPortfolio,
  ISkill,
  ICategorySkill,
  ITag,
  IWork,
  StatusT,
  IPortfolioProject,
  CategoryItemId,
} from './AboutUs.types';
import {
  addSkillToCategory,
  addTagToCategory,
  createPortfolio,
  removeSkillFromCategory,
  removeTagFromCategory,
  updateAdditionalInfo,
  updateLanguage,
} from 'api/PortfolioAPI';
import { createTag, updateTag } from 'api/TagsAPI';
import { updatePerson } from 'api/PeopleAPI';
import { createPortfolioProject, ProjectPayload, updatePortfolioProject } from 'api/PortfolioProjectsAPI';
import { addSummaryItemToPortfolioEmployment } from 'api/PortfolioEmploymentAPI';
import { addSummaryItemToPortfolioEducation } from 'api/PortfolioEducationAPI';
import { createSkill, updateSkill, addTagToSkill, removeTagFromSkill } from 'api/PortfolioSkillsAPI';
import {
  getAddedSkillNotification,
  getAddedTagNotification,
  getCreatedItemNotification,
  getRemovedSkillNotification,
  getRemovedTagNotification,
  getUpdatedItemNotification,
} from 'components/notification/NotificationSnackbar';
import {
  getSelectedSkillToEdit,
  setShowAddProjectDialog,
  setShowAddSkillDialog,
  setShowAddTagDialog,
  setShowUpdateProjectDialog,
  setShowUpdateSkillDialog,
  setShowUpdateTagDialog,
} from './aboutUsSlice';
import { canUserEdit, getUserId } from 'components/user/userSlice';
import { getAllPortfolioImages } from 'api/ImageAPI';
import { LogStyle } from 'app/constants';
import { IStorageItem } from './Portfolio.types';

export interface TagsState {
  tags?: Array<ITag>;
  tagsLoaded: boolean;
}

export interface ActiveState extends TagsState {
  activePerson?: IPerson;
  activePersonStatus: StatusT;
  activePortfolio?: IPersonPortfolio;
  activePortfolioStatus: StatusT;
}

const initialState: ActiveState = {
  tags: [],
  tagsLoaded: false,
  activePerson: undefined,
  activePersonStatus: 'empty',
  activePortfolio: undefined,
  activePortfolioStatus: 'empty',
};

const isEmptyParams = (portfolioId: string, skillId: string, tagId: string): boolean => {
  return isEmptyString(tagId) || isEmptyString(skillId) || isEmptyString(portfolioId);
};

export type DocId = string;
export type MatchedDocs = Record<DocId, ResultItemT>;
export type CreateProjectT = {
  portfolioId: string;
  projectName: string;
  fromDate: Date;
  toDate?: Date;
  screenshots?: Array<string>;
};
export type UpdateProjectT = {
  projectId: string;
} & CreateProjectT;

/**
 * Get active user's image urls stored in Firebase Storage inside a portfolio folder
 */
export const doGetAllFBStoragePortfolioImageUrls = (): AppThunk => async (dispatch, getState) => {
  if (canUserEdit(getState())) {
    const userId = getUserId(getState());
    const imageUrls: Array<IStorageItem> = await getAllPortfolioImages(`images/${userId}/portfolio/`);
    console.log(
      `%cactiveSlice %c Fetched ${imageUrls.length} image(s) from storage`,
      LogStyle.FCTitle,
      LogStyle.FCPDefaultText
    );
    
    dispatch(setActivePortfolioStorageItems({items: imageUrls, append: false}));
  }
};

export const doCreatePortfolioProject =
  ({ portfolioId, projectName, fromDate, toDate, screenshots }: CreateProjectT): AppThunk =>
  async (dispatch, getState) => {
    if (isEmptyString(projectName.trim())) {
      dispatch(setSnackbarError({ openSnackbar: true, snackbarMessage: 'Empty Project name' }));
      return;
    }
    const userUid = getState().user.uid;
    let existingProjects = getState().active.activePortfolio?.projects || [];

    const addedProject: IPortfolioProject = await createPortfolioProject(portfolioId, userUid, {
      name: projectName.trim(),
      fromDate: fromDate,
      ...(toDate && { toDate: toDate }),
      ...(screenshots && { screenshots: screenshots }),
    });

    dispatch(setActivePortfolioProjects([addedProject, ...existingProjects]));
    dispatch(setCategoryName({ id: addedProject.id, name: addedProject.name }));
    dispatch(setShowAddProjectDialog(false));
    const notification = getCreatedItemNotification({ name: projectName, item: 'project', category: 'project' });
    dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: notification }));
  };

export const doUpdatePortfolioProject =
  ({ portfolioId, projectId, projectName, fromDate, toDate, screenshots }: UpdateProjectT): AppThunk =>
  async (dispatch) => {
    if (isEmptyString(projectName.trim())) {
      dispatch(setSnackbarError({ openSnackbar: true, snackbarMessage: 'Empty Project name' }));
      return;
    }
    dispatch(
      savePortfolioProjectUpdates(portfolioId, projectId, {
        name: projectName.trim(),
        fromDate: fromDate,
        ...(toDate && { toDate: toDate }),
        ...(screenshots && { screenshots: screenshots }),
      })
    );
  };

export const doUpdatePortfolioProjectSummary =
  (categoryId: string, categoryName: string, summaryItem: string): AppThunk =>
  async (dispatch, getState) => {
    const portfolio = getState().active.activePortfolio as IPersonPortfolio;
    console.log(`called doUpdatePortfolioProject = ${summaryItem}, ${categoryId}. ${categoryName}`);
    dispatch(
      savePortfolioProjectUpdates(portfolio.id, categoryId, {
        description: summaryItem,
      })
    );
  };

const savePortfolioProjectUpdates =
  (portfolioId: string, projectId: string, payload: ProjectPayload): AppThunk =>
  async (dispatch, getState) => {
    const userUid = getState().user.uid;
    let existingProjects = getState().active.activePortfolio?.projects || [];

    const updatedProject: IPortfolioProject = await updatePortfolioProject(portfolioId, projectId, userUid, payload);

    const updatedProjects: Array<IPortfolioProject> = existingProjects.map((cat) => {
      if (cat.id === updatedProject.id) {
        return { ...updatedProject };
      }
      return { ...cat };
    });

    dispatch(setActivePortfolioProjects(updatedProjects));
    dispatch(setShowUpdateProjectDialog(false));
    const notification = getUpdatedItemNotification({
      name: updatedProject.name,
      item: 'project',
      category: 'project',
    });
    dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: notification }));
  };

export const doCreateSkill =
  (
    portfolioId: string,
    skillName: string,
    skillDescription: string,
    skillLevel: string,
    showInSkillsHighlight: boolean
  ): AppThunk =>
  async (dispatch, getState) => {
    if (isEmptyString(skillName.trim())) {
      dispatch(setSnackbarError({ openSnackbar: true, snackbarMessage: 'Empty Skill name' }));
      return;
    }
    const userUid = getState().user.uid;
    const skills = getSkills(getState());
    const skill = await createSkill(
      portfolioId,
      skillName.trim(),
      skillDescription.trim(),
      skillLevel,
      showInSkillsHighlight,
      userUid
    );
    const newSkills: Array<ISkill> = [...skills, skill];
    newSkills.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
    );

    dispatch(setSkillsToActivePortfolio(newSkills));
    dispatch(setShowAddSkillDialog(false));
    const notification = getCreatedItemNotification({ name: skill.name, item: 'skill', category: 'skill' });
    dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: notification }));
  };

export const doUpdateSkill =
  (
    portfolioId: string,
    skillId: string,
    skillName: string,
    skillDescription: string,
    skillLevel: string,
    includeInSkillsHighlight: boolean
  ): AppThunk =>
  async (dispatch, getState) => {
    if (isEmptyString(skillName.trim())) {
      dispatch(setSnackbarError({ openSnackbar: true, snackbarMessage: 'Empty Skill name' }));
      return;
    }

    const userUid = getState().user.uid;
    const skillTags = getSelectedSkillToEdit(getState())?.tags || [];
    const skills = getSkills(getState()).filter((s: ISkill) => s.id !== skillId);
    const skill = await updateSkill(
      portfolioId,
      skillId,
      skillName.trim(),
      skillDescription.trim(),
      skillLevel,
      includeInSkillsHighlight,
      userUid
    );

    skill.tags = [...skillTags];
    const newSkills: Array<ISkill> = [...skills, skill];
    newSkills.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
    );

    dispatch(setSkillsToActivePortfolio(newSkills));
    dispatch(setShowUpdateSkillDialog(false));
    const notification = getUpdatedItemNotification({ name: skill.name, item: 'skill', category: 'skill' });
    dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: notification }));
  };

export const doCreateTag =
  (tagName: string): AppThunk =>
  async (dispatch, getState) => {
    if (isEmptyString(tagName.trim())) {
      dispatch(setSnackbarError({ openSnackbar: true, snackbarMessage: 'Empty Tag name' }));
      return;
    }

    try {
      const userUid = getState().user.uid;
      const tagsList = getTags(getState());
      const tag: ITag = await createTag(tagName.trim(), userUid);

      const newList: Array<ITag> = [...tagsList, tag];
      newList.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

      dispatch(setTags(newList));
      dispatch(setTagsLoaded(true));
      const notification = getCreatedItemNotification({ name: tagName, item: 'tag', category: 'tag' });
      dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: notification }));
      dispatch(setShowAddTagDialog(false));
    } catch (reason: any) {
      const fbError = reason as FirebaseError;
      if (fbError.code === 'permission-denied') {
        const userId: string = getState().user.userStatus === 'signedIn' ? getState().user.uid : 'not logged in';
        logEvent(analytics, 'unauthorized-firestore-write-attempt', { code: fbError.code, maybeUserId: userId });
      }
      console.log(`aboutUsSlice | doCreateTag | code: ${fbError.code}, ${fbError.message}`);
      dispatch(setTagsLoaded(false));
      dispatch(setSnackbarError({ openSnackbar: true, snackbarMessage: `Cannot create Tag ${tagName}` }));
    }
  };

export const doUpdateTag =
  (tag: ITag): AppThunk =>
  async (dispatch, getState) => {
    if (isEmptyString(tag.name.trim())) {
      dispatch(setSnackbarError({ openSnackbar: true, snackbarMessage: 'Empty Tag name' }));
      return;
    }

    const userUid = getState().user.uid;
    const tagsList = getTags(getState()).filter((t: ITag) => t.id !== tag.id);
    const updatedTag: ITag = await updateTag(tag, userUid);
    const newList: Array<ITag> = [...tagsList, updatedTag];
    newList.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

    dispatch(setTags(newList));
    dispatch(setTagsLoaded(true));
    dispatch(setShowUpdateTagDialog(false));
    const notification = getUpdatedItemNotification({ name: tag.name, item: 'tag', category: 'tag' });
    dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: notification }));
  };

export const doAddTagToSkill =
  (portfolioId: string, skillId: string, tagId: string): AppThunk =>
  async (dispatch, getState) => {
    if (isEmptyParams(portfolioId, skillId, tagId)) {
      dispatch(setSnackbarError({ openSnackbar: true, snackbarMessage: 'Empty values are not allowed' }));
      return;
    }
    const name = getTagNameById(getState(), tagId) as string;
    const item = getSkillNameById(getState(), skillId) as string;
    const userUid = getState().user.uid;
    const portfolio = getActivePortfolio(getState()) as IPersonPortfolio;
    const skills = await addTagToSkill(portfolioId, skillId, tagId, userUid);
    dispatch(setActivePortfolio({ ...portfolio, skills: [...skills] }));
    const tagNotification = getAddedTagNotification({ name, item, category: 'skill' });
    dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: tagNotification }));
  };

export const doRemoveTagFromSkill =
  (portfolioId: string, skillId: string, tagId: string): AppThunk =>
  async (dispatch, getState) => {
    if (isEmptyParams(portfolioId, skillId, tagId)) {
      dispatch(setSnackbarError({ openSnackbar: true, snackbarMessage: 'Empty values are not allowed' }));
      return;
    }
    const name = getTagNameById(getState(), tagId) as string;
    const item = getSkillNameById(getState(), skillId) as string;
    const userUid = getState().user.uid;
    const portfolio = getActivePortfolio(getState()) as IPersonPortfolio;
    const skills = await removeTagFromSkill(portfolioId, skillId, tagId, userUid);
    dispatch(setActivePortfolio({ ...portfolio, skills: [...skills] }));
    const tagNotification = getRemovedTagNotification({ name, item, category: 'skill' });
    dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: tagNotification }));
  };

export const doAddSkillToCategory =
  (portfolioId: string, category: string, categoryId: string, skill: ISkill, userUid: string): AppThunk =>
  async (dispatch) => {
    if (isEmptyParams(portfolioId, category, categoryId) || !skill) {
      dispatch(setSnackbarError({ openSnackbar: true, snackbarMessage: 'Empty values are not allowed' }));
      return;
    }
    const skillData = { skillId: skill.id, skillName: skill.name, skillUse: '' };
    if (category === 'employment') {
      dispatch(doAddSkillToEmploymentDoc(portfolioId, categoryId, skillData, userUid));
    } else {
      dispatch(doAddSkillToEducationDoc(portfolioId, categoryId, skillData));
    }
  };

export const doRemoveSkillFromCategory =
  (portfolioId: string, category: string, categoryId: string, skill: ICategorySkill, userUid: string): AppThunk =>
  async (dispatch) => {
    if (isEmptyParams(portfolioId, category, categoryId) || !skill) {
      dispatch(setSnackbarError({ openSnackbar: true, snackbarMessage: 'Empty values are not allowed' }));
      return;
    }
    if (category === 'employment') {
      dispatch(doRemoveSkillFromEmploymentDoc(portfolioId, categoryId, skill, userUid));
    } else {
      dispatch(doRemoveSkillFromEducationDoc(portfolioId, categoryId, skill));
    }
  };

export const doAddSkillToEmploymentDoc =
  (portfolioId: string, categoryId: string, skill: ICategorySkill, userUid: string): AppThunk =>
  async (dispatch, getState) => {
    let activeCategory = getState().active.activePortfolio?.employment as Array<IWork>;
    const skills = await addSkillToCategory(portfolioId, skill, 'employment', categoryId, userUid);
    console.log(`doAddSkillToEmploymentDoc: ${categoryId} found ${skills}`);
    const updatedCategories: Array<IWork> = activeCategory?.map((cat) => {
      return cat.id === categoryId ? { ...cat, skills: [...skills] } : { ...cat };
    });

    dispatch(setActivePortfolioEmploymentCategory(updatedCategories as Array<IWork>));
    const item = getCategoryItemName(getState(), categoryId);
    const skillNotification = getAddedSkillNotification({ name: skill.skillName, item, category: 'employment' });
    dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: skillNotification }));
  };

export const doRemoveSkillFromEmploymentDoc =
  (portfolioId: string, categoryId: string, skill: ICategorySkill, userUid: string): AppThunk =>
  async (dispatch, getState) => {
    let activeCategory = getState().active.activePortfolio?.employment as Array<IWork>;

    const skills = await removeSkillFromCategory(portfolioId, skill, 'employment', categoryId, userUid);
    const updatedCategories: Array<IWork> = activeCategory?.map((cat) => {
      return cat.id === categoryId ? { ...cat, skills: [...skills] } : { ...cat };
    });

    dispatch(setActivePortfolioEmploymentCategory(updatedCategories as Array<IWork>));

    const item = getCategoryItemName(getState(), categoryId);
    const skillNotification = getRemovedSkillNotification({ name: skill.skillName, item, category: 'employment' });
    dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: skillNotification }));
  };

export const doAddSkillToEducationDoc =
  (portfolioId: string, categoryId: string, skill: ICategorySkill): AppThunk =>
  async (dispatch, getState) => {
    let activeCategory = getState().active.activePortfolio?.education as Array<IEducation>;
    const userUid = getState().user.uid;
    const skills = await addSkillToCategory(portfolioId, skill, 'education', categoryId, userUid);

    const updatedCategories: Array<IEducation> = activeCategory?.map((cat) => {
      return cat.id === categoryId ? { ...cat, skills: [...skills] } : { ...cat };
    });

    dispatch(setActivePortfolioEducationCategory(updatedCategories as Array<IEducation>));
    const item = getCategoryItemName(getState(), categoryId);
    const skillNotification = getAddedSkillNotification({ name: skill.skillName, item, category: 'education' });
    dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: skillNotification }));
  };

export const doRemoveSkillFromEducationDoc =
  (portfolioId: string, categoryId: string, skill: ICategorySkill): AppThunk =>
  async (dispatch, getState) => {
    let activeCategory = getState().active.activePortfolio?.education as Array<IEducation>;
    const userUid = getState().user.uid;
    const skills = await removeSkillFromCategory(portfolioId, skill, 'education', categoryId, userUid);
    const updatedCategories: Array<IEducation> = activeCategory?.map((cat) => {
      return cat.id === categoryId ? { ...cat, skills: [...skills] } : { ...cat };
    });

    dispatch(setActivePortfolioEducationCategory(updatedCategories as Array<IEducation>));
    const item = getCategoryItemName(getState(), categoryId);
    const skillNotification = getRemovedSkillNotification({ name: skill.skillName, item, category: 'education' });
    dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: skillNotification }));
  };

export const doAddTagToCategoryDoc =
  (categoryId: string, categoryName: string, tag: ITag): AppThunk =>
  async (dispatch, getState) => {
    const portfolio = getState().active.activePortfolio as IPersonPortfolio;
    const userUid = getState().user.uid;
    const tags = await addTagToCategory(portfolio.id, categoryName, categoryId, tag, userUid);
    dispatch(setActivePortfolioCategoryDocumentTags({ category: categoryName, categoryId: categoryId, tags }));
    const item = getCategoryItemName(getState(), categoryId);
    const tagNotification = getAddedTagNotification({ name: tag.name, item, category: categoryName });
    dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: tagNotification }));
  };

export const doRemoveTagFromCategoryDoc =
  (categoryId: string, categoryName: string, tag: ITag): AppThunk =>
  async (dispatch, getState) => {
    const portfolio = getState().active.activePortfolio as IPersonPortfolio;
    const userUid = getState().user.uid;
    const tags = await removeTagFromCategory(portfolio.id, categoryName, categoryId, tag, userUid);
    dispatch(setActivePortfolioCategoryDocumentTags({ category: categoryName, categoryId: categoryId, tags }));
    const item = getCategoryItemName(getState(), categoryId);
    const tagNotification = getRemovedTagNotification({ name: tag.name, item, category: categoryName });
    dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: tagNotification }));
  };

export const doUpdatePersonInfo =
  (personInfo: Partial<IPerson>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setActivePersonStatus('loading'));
    const activePerson = getActivePerson(getState()) as IPerson;
    const userUid = getState().user.uid;
    const personPath = `people/${activePerson.id}`;
    const updatedPerson = await updatePerson(personPath, personInfo, userUid);
    dispatch(setActivePerson(updatedPerson));
    dispatch(setActivePersonStatus('loaded'));
    dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: `Updated ${updatedPerson.firstname}` }));
  };

export const doUpdatePortfolioEmployment =
  (categoryId: string, categoryName: string, summaryItem: string): AppThunk =>
  async (dispatch, getState) => {
    let activeCategory = getState().active.activePortfolio?.employment as Array<IWork>;
    const portfolio = getState().active.activePortfolio as IPersonPortfolio;
    const userUid = getState().user.uid;
    console.log(`called doUpdatePortfolioEmployment = ${summaryItem}, ${categoryId}. ${categoryName}`);
    const updatedCategoryDoc = await addSummaryItemToPortfolioEmployment(
      portfolio.id,
      categoryId,
      summaryItem,
      userUid
    );

    const updatedCategories: Array<IWork> = activeCategory?.map((cat) => {
      return cat.id === updatedCategoryDoc.id ? { ...updatedCategoryDoc } : { ...cat };
    });

    dispatch(setActivePortfolioEmploymentCategory(updatedCategories));
    const name = getCategoryItemName(getState(), categoryId);
    const notification = getUpdatedItemNotification({ name, item: 'work', category: 'employment' });
    dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: notification }));
  };

export const doUpdatePortfolioEducation =
  (categoryId: string, categoryName: string, summaryItem: string): AppThunk =>
  async (dispatch, getState) => {
    let activeCategory = getState().active.activePortfolio?.education as Array<IEducation>;
    const portfolio = getState().active.activePortfolio as IPersonPortfolio;
    const userUid = getState().user.uid;
    console.log(`called doUpdatePortfolioEducation = ${summaryItem}, ${categoryId}. ${categoryName}`);
    const updatedCategoryDoc = await addSummaryItemToPortfolioEducation(portfolio.id, categoryId, summaryItem, userUid);

    const updatedCategories: Array<IEducation> = activeCategory?.map((cat) => {
      return cat.id === updatedCategoryDoc.id ? { ...updatedCategoryDoc } : { ...cat };
    });

    dispatch(setActivePortfolioEducationCategory(updatedCategories));
    const name = getCategoryItemName(getState(), categoryId);
    const notification = getUpdatedItemNotification({ name, item: 'school', category: 'education' });

    dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: notification }));
  };

export const doUpdatePortfolioSummary =
  (categoryId: string, categoryName: string, summaryItem: string): AppThunk =>
  async (dispatch) => {
    if (categoryName === 'education') {
      dispatch(doUpdatePortfolioEducation(categoryId, categoryName, summaryItem));
    } else if (categoryName === 'employment') {
      dispatch(doUpdatePortfolioEmployment(categoryId, categoryName, summaryItem));
    }
  };

export const doUpdateLanguage =
  (languages: Array<string>): AppThunk =>
  async (dispatch, getState) => {
    const portfolio = getState().active.activePortfolio as IPersonPortfolio;
    const userUid = getState().user.uid;
    const addedLanguages = await updateLanguage(portfolio.id, languages, userUid);
    dispatch(setActivePortfolioLanguages(addedLanguages));
    const notification = getUpdatedItemNotification({ name: 'Languages', item: 'lang', category: 'category' });
    dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: notification }));
  };

export const doUpdateAdditionalInfo =
  (info: Array<string>): AppThunk =>
  async (dispatch, getState) => {
    const portfolio = getState().active.activePortfolio as IPersonPortfolio;
    const userUid = getState().user.uid;
    const addedInfo = await updateAdditionalInfo(portfolio.id, info, userUid);
    dispatch(setActivePortfolioAdditionalInfo(addedInfo));
    const notification = getUpdatedItemNotification({ name: 'Additional Info', item: 'xinfo', category: 'category' });
    dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: notification }));
  };

export const doCreatePortfolio = (): AppThunk => async (dispatch, getState) => {
  const person = getState().active.activePerson as IPerson;
  const userUid = getState().user.uid;
  const updatedPerson = await createPortfolio(person, userUid);
  dispatch(setActivePerson(updatedPerson));
  const notification = getCreatedItemNotification({ name: 'portfolio', item: 'portfolio', category: 'category' });
  dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: notification }));
};  

type SetCategoryDocumentTagsType = {
  tags: Array<string>;
  category: string;
  categoryId: string;
};
type CategoryNameItemType = {
  id: CategoryItemId;
  name: string;
};
type StorageItemsType = {
  items: Array<IStorageItem>;
  append?: boolean;
};

export const activeSlice = createSlice({
  name: 'active',
  initialState,
  reducers: {
    setTags: (state, action: PayloadAction<Array<ITag>>) => {
      state.tags = action.payload;
    },
    setTagsLoaded: (state, action: PayloadAction<boolean>) => {
      state.tagsLoaded = action.payload;
    },
    setCategoryName: (state, action: PayloadAction<CategoryNameItemType>) => {
      (state.activePortfolio as IPersonPortfolio).categoryItemNamesById[action.payload.id] = action.payload.name;
    },
    setActivePortfolio: (state, action: PayloadAction<IPersonPortfolio>) => {
      state.activePortfolio = action.payload;
    },
    setActivePortfolioStatus: (state, action: PayloadAction<StatusT>) => {
      state.activePortfolioStatus = action.payload;
    },
    setActivePerson: (state, action: PayloadAction<IPerson>) => {
      state.activePerson = action.payload;
    },
    setActivePersonStatus: (state, action: PayloadAction<StatusT>) => {
      state.activePersonStatus = action.payload;
    },
    setSkillsToActivePortfolio: (state, action: PayloadAction<Array<ISkill>>) => {
      (state.activePortfolio as IPersonPortfolio).skills = action.payload;
    },
    setActivePortfolioEducationCategory: (state, action: PayloadAction<Array<IEducation>>) => {
      (state.activePortfolio as IPersonPortfolio).education = action.payload;
    },
    setActivePortfolioEmploymentCategory: (state, action: PayloadAction<Array<IWork>>) => {
      (state.activePortfolio as IPersonPortfolio).employment = action.payload;
    },
    setActivePortfolioProjects: (state, action: PayloadAction<Array<IPortfolioProject>>) => {
      (state.activePortfolio as IPersonPortfolio).projects = action.payload;
    },
    setActivePortfolioEmploymentDocument: (state, action: PayloadAction<Array<IWork>>) => {
      (state.activePortfolio as IPersonPortfolio).employment = action.payload;
    },
    setActivePortfolioCategoryDocumentTags: (state, action: PayloadAction<SetCategoryDocumentTagsType>) => {
      const portfolio = state.activePortfolio as IPersonPortfolio;

      if (action.payload.category === 'employment') {
        const employmentList = portfolio.employment as Array<IWork>;
        (state.activePortfolio as IPersonPortfolio).employment = employmentList.map((work) => {
          if (work.id === action.payload.categoryId) {
            return { ...work, tags: action.payload.tags };
          }
          return { ...work };
        });
      } else if (action.payload.category === 'projects') {
        const projectList = portfolio.projects as Array<IPortfolioProject>;
        (state.activePortfolio as IPersonPortfolio).projects = projectList.map((project) => {
          if (project.id === action.payload.categoryId) {
            return { ...project, tags: action.payload.tags };
          }
          return { ...project };
        });
      } else {
        const educationList = portfolio.education as Array<IEducation>;
        (state.activePortfolio as IPersonPortfolio).education = educationList.map((school) => {
          if (school.id === action.payload.categoryId) {
            return { ...school, tags: action.payload.tags };
          }
          return { ...school };
        });
      }
    },
    setActivePortfolioLanguages: (state, action: PayloadAction<Array<string>>) => {
      (state.activePortfolio as IPersonPortfolio).languages = action.payload;
    },
    setActivePortfolioAdditionalInfo: (state, action: PayloadAction<Array<string>>) => {
      (state.activePortfolio as IPersonPortfolio).additionalInfo = action.payload;
    },
    setActivePortfolioStorageItems: (state, action: PayloadAction<StorageItemsType>) => {
      (state.activePortfolio as IPersonPortfolio).storageImages = action.payload.append
        ? [...(state.activePortfolio as IPersonPortfolio).storageImages || [], ...action.payload.items]
        : action.payload.items;
    },
  },
});

export const {
  setTags,
  setTagsLoaded,
  setActivePerson,
  setActivePersonStatus,
  setActivePortfolioStatus,
  setActivePortfolio,
  setSkillsToActivePortfolio,
  setActivePortfolioEducationCategory,
  setActivePortfolioEmploymentCategory,
  setActivePortfolioCategoryDocumentTags,
  setActivePortfolioProjects,
  setActivePortfolioLanguages,
  setActivePortfolioAdditionalInfo,
  setActivePortfolioStorageItems,
  setCategoryName,
} = activeSlice.actions;

export const getTags = (state: RootState): Array<ITag> => state.active.tags || [];
export const isTagsLoaded = (state: RootState): boolean => state.active.tagsLoaded;

export const getActivePortfolioId = (state: RootState) => state.active.activePortfolio?.id;
export const getActivePortfolio = (state: RootState) => state.active.activePortfolio;
export const isActivePortfolioLoaded = (state: RootState) => state.active.activePortfolioStatus === 'loaded';
export const isActivePortfolioIdEquals = (state: RootState, id: string) =>
  isActivePortfolioLoaded(state) && getActivePortfolio(state)?.id === id;
export const getActivePerson = (state: RootState) => state.active.activePerson;
export const isActivePersonLoaded = (state: RootState) => state.active.activePersonStatus === 'loaded';
export const shouldRevalidateActivePerson = (state: RootState) => state.active.activePerson?.status === 'revalidate';
export const isActivePersonIdEquals = (state: RootState, id: string) =>
  isActivePersonLoaded(state) && getActivePerson(state)?.id === id;
export const getSkillsFromEmploymentCollectionByDocId = (state: RootState, categoryId: string) => {
  const portfolio: IPersonPortfolio = getActivePortfolio(state) as IPersonPortfolio;
  const categoryCollection = portfolio.employment;
  return categoryCollection?.find((cat) => cat.id === categoryId);
};

export const getSkillsFromEducationCollectionByDocId = (state: RootState, categoryId: string) => {
  const portfolio: IPersonPortfolio = getActivePortfolio(state) as IPersonPortfolio;
  const categoryCollection = portfolio.education;
  return categoryCollection?.find((cat) => cat.id === categoryId);
};
export const getCategoryItemName = (state: RootState, categoryId: string): string =>
  (getActivePortfolio(state) as IPersonPortfolio).categoryItemNamesById[categoryId];
export const getTagNameById = (state: RootState, tagId: string) => getTags(state).find((tag) => tag.id === tagId)?.name;
export const getSkillNameById = (state: RootState, skillId: string) =>
  ((getActivePortfolio(state) as IPersonPortfolio).skills as Array<ISkill>).find((skill) => skill.id === skillId)?.name;
export const getSkills = (state: RootState) => (getActivePortfolio(state) as IPersonPortfolio).skills as Array<ISkill>;
export const getHighlightedSkills = (state: RootState) =>
  ((getActivePortfolio(state) as IPersonPortfolio).skills as Array<ISkill>).filter(
    (skill) => skill.includeInSkillsHighlight
  );

export const getPortfolioStorageItemsUrls = (state: RootState): Array<IStorageItem> =>
  getActivePortfolio(state) ? (getActivePortfolio(state) as IPersonPortfolio).storageImages || [] : [];

export default activeSlice.reducer;
