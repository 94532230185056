import { db } from '../firebase/Firebase';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import {
  IWork,
} from 'scenes/aboutus/AboutUs.types';


export const loadEmploymentData = async (portfolioId: string): Promise<Array<IWork>> => {
  let employmentItems: Array<IWork> = [];
  const q = query(collection(db, `portfolio/${portfolioId}/employment`), orderBy('toDate', 'desc'));
  const employmentsSnapshot = await getDocs(q);
  employmentsSnapshot.docs.forEach((doc) => {
    const docData = doc.data();
    employmentItems.push({
      id: doc.id,
      name: docData.name,
      location: docData.location,
      title: docData.title,
      description: docData.description ? docData.description.split('|') : ['No description'],
      fromDate: docData.fromDate?.toDate().toString(),
      toDate: docData.toDate?.toDate().toString(),
      logo: docData.logo,
      skills: docData.skills,
      tags: docData.tags,
    });
  });
  return employmentItems;
};

export const loadEmploymentDocument = async (portfolioId: string, employmentId: string): Promise<IWork> => {
  const docRef = doc(db, `portfolio/${portfolioId}/employment/${employmentId}`);
  const employmentDocSnap = await getDoc(docRef);
  if (employmentDocSnap.exists()) {
    let employmentDoc: IWork;
    const docData = employmentDocSnap.data();

    employmentDoc = {
      id: employmentId,
      name: docData.name,
      location: docData.location,
      title: docData.title,
      description: docData.description ? docData.description.split('|') : ['No description'],
      fromDate: docData.fromDate?.toDate().toString(),
      toDate: docData.toDate?.toDate().toString(),
      logo: docData.logo,
      skills: docData.skills,
      tags: docData.tags,
    };
    return employmentDoc;
  } else {
    console.error('No such document!');
    throw Error(`Employment item does not exist for Path=portfolio/${portfolioId}/employment/${employmentId}`);
  }
};

export const addSummaryItemToPortfolioEmployment = async (
  portfolioId: string,
  categoryId: string,
  summaryItem: string,
  userUid: string
): Promise<IWork> => {
  const summaryRef = doc(db, `portfolio/${portfolioId}/employment/${categoryId}`);
  await updateDoc(summaryRef, {
    description: summaryItem,
    modifiedBy: userUid,
    modifiedAt: serverTimestamp(),
  });

  return loadEmploymentDocument(portfolioId, categoryId);
};

