export enum SceneType {
  Home = 'home',
  Skills = 'skills',
  Projects = 'projects',
  School = 'school',
  Employment = 'employment',
  Contact = 'contact',
  AboutUs = 'aboutus',
  // Technology = "Technology",
}

export type SceneProps = {
  className?: string;
} & React.HTMLAttributes<HTMLAllCollection>;

export type HeaderConfig = {
  title: string;
  navItem: SceneType;
  actionItems: SceneType[];
};

export type SceneItem = {
  type: SceneType;
  title: string;
  icon?: string;
  onIcon?: string;
  iconLabel?: string;
  drawerIcon?: string;
  onDrawerIcon?: string;
  drawerIconLabel?: string;
  active?: boolean;
  header?: HeaderConfig;
};

export type SceneHeaderConfig = {
  title: string;
  navItem: SceneItem;
  actionItems: SceneItem[];
};

export interface Dictionary<T> {
  [key: string]: T;
}

export type MessagePayloadT = {
  senderName: string;
  senderPhone: string;
  senderEmail: string;
  message: string;
  messageSubject: string;
};
