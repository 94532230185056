import { db } from '../firebase/Firebase';
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { IPortfolioProject } from 'scenes/aboutus/AboutUs.types';

export type ProjectPayload = {
  name?: string;
  fromDate?: Date;
  toDate?: Date;
  description?: string;
  screenshots?: Array<string>;
};

const getPortfolioProjectModel = (projectId: string, docData: DocumentData): IPortfolioProject => {
  return {
    id: projectId,
    name: docData.name,
    location: docData.location || 'Vancouver, BC, Canada',
    title: docData.title || 'No title',
    description: docData.description ? docData.description.split('|') : ['No description'],
    fromDate: docData.fromDate?.toDate().toString(),
    toDate: docData.toDate?.toDate().toString(),
    logo: docData.logo || '/images/logo.webp',
    skills: docData.skills || [],
    tags: docData.tags || [],
    screenshots: docData.screenshots,
  };
};

export const loadPortfolioProjects = async (portfolioId: string): Promise<Array<IPortfolioProject>> => {
  let projectDocs: Array<IPortfolioProject> = [];
  const q = query(collection(db, `portfolio/${portfolioId}/projects`), orderBy('fromDate', 'desc'));
  const projectsSnapshot = await getDocs(q);
  projectsSnapshot.docs.forEach((doc) => {
    const docData = doc.data();
    projectDocs.push(getPortfolioProjectModel(doc.id, docData));
  });
  return projectDocs;
};

export const loadPortfolioProject = async (portfolioId: string, projectId: string): Promise<IPortfolioProject> => {
  const docRef = doc(db, `portfolio/${portfolioId}/projects/${projectId}`);
  const projectSnapshot = await getDoc(docRef);
  if (projectSnapshot.exists()) {
    const docData = projectSnapshot.data();
    return getPortfolioProjectModel(projectId, docData);
  } else {
    console.error('No such document!');
    throw Error(`Project does not exist for Path=portfolio/${portfolioId}/projects/${projectId}`);
  }
};

export const updatePortfolioProject = async (
  portfolioId: string,
  categoryId: string,
  userUid: string,
  updatedProject: ProjectPayload
): Promise<IPortfolioProject> => {
  const projectRef = doc(db, `portfolio/${portfolioId}/projects/${categoryId}`);
  await updateDoc(projectRef, {
    ...updatedProject,
    ...(updatedProject.fromDate && { fromDate: Timestamp.fromDate(updatedProject.fromDate) }),
    ...(updatedProject.toDate && { toDate: Timestamp.fromDate(updatedProject.toDate) }),
    modifiedBy: userUid,
    modifiedAt: serverTimestamp(),
  });

  return loadPortfolioProject(portfolioId, categoryId);
};

export const createPortfolioProject = async (
  portfolioId: string,
  userUid: string,
  projectData: ProjectPayload
): Promise<IPortfolioProject> => {
  const newProject = {
    name: projectData.name,
    fromDate: Timestamp.fromDate(projectData.fromDate || new Date()),
    ...(projectData.toDate && { toDate: Timestamp.fromDate(projectData.toDate) }),
    screenshots: [],
    description: '',
    tags: [],
    skills: [],
    createdBy: userUid,
    createdAt: serverTimestamp(),
  };
  const docRef = await addDoc(collection(db, `portfolio/${portfolioId}/projects/`), newProject);
  console.log(`Document written with ID:  ${docRef.id}, user: ${userUid}`);
  return loadPortfolioProject(portfolioId, docRef.id);
};
