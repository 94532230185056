import React, { useEffect, useState } from 'react';
import { useRouteError, useNavigate } from 'react-router-dom';

import styles from './ErrorBoundary.module.scss';

const ErrorBoundary: React.FC = () => {
  let error = useRouteError();
  const [timeToRedirect, setTimeToRedirect] = useState<number>(5);
  const navigate = useNavigate();

  useEffect(() => {
    if (timeToRedirect > 0) {
      setTimeout(() => {
        setTimeToRedirect(timeToRedirect - 1);
      }, 1000);
    } else {
      navigate(-1);
    }
  }, [error, navigate, timeToRedirect]);
  
  if (timeToRedirect === 5) {
    console.error(error);
  } 
  
  return (
    <div className={styles.ErrorBoundary}>
      <div>
      <span className={styles.ErrorBoundaryErrorText}>Oops... </span>
        <img src="/images/eleventheye-logo.webp" width={50} height={50} alt="eleventheye logo" />
        <span className={styles.ErrorBoundaryErrorText}>RROR</span>
      </div>
      <p>
        <div>
          Let us try to navigate you
          <p className={styles.ErrorBoundaryCountdown}>in {timeToRedirect} seconds</p>
          <p>
            <span className={styles.ErrorBoundaryGreenerPastures}>to the greener pastures</span>
          </p>
        </div>
      </p>
      <p>Remain calm...</p>
    </div>
  );
};

export default ErrorBoundary;
