import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { analytics, db, logEvent } from '../../firebase/Firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { MessagePayloadT } from 'scenes/Scene.types';
import { setSnackbarError, setSnackbarSuccess } from 'scenes/scenesSlice';

export type ContactSceneStatusT = 'idle' | 'message-sending' | 'message-sent-success' | 'message-sent-error';

export interface ContactSceneMessage {
  message: string;
  messageSubject: string;
  senderName: string;
  senderEmail: string;
  senderPhone: string;
}

export interface ContactSceneState extends ContactSceneMessage {
  status: ContactSceneStatusT;
}

const initialState: ContactSceneState = {
  message: '',
  messageSubject: '',
  senderName: '',
  senderEmail: '',
  senderPhone: '',
  status: 'idle',
};

export const sendUserMessage = (): AppThunk => (dispatch, useState) => {
  dispatch(setStatus('message-sending'));

  if (!getMessage(useState())) {
    setTimeout(() => {
      dispatch(setSnackbarError({ openSnackbar: true, snackbarMessage: 'Please enter a message' }));
      dispatch(setStatus('idle'));
    }, 500);
  } else {
    const messagePayload: MessagePayloadT = getContactSceneMessage(useState());
    dispatch(sendMessage(messagePayload));
  }
};

export const sendMessage =
  (messagePayload: MessagePayloadT): AppThunk =>
  async (dispatch, useState) => {
    dispatch(setStatus('message-sending'));

    try {
      const docRef = await addDoc(collection(db, 'messages'), {
        ...messagePayload,
        createdAt: serverTimestamp(),
        modifiedAt: serverTimestamp(),
      });
      logEvent(analytics, 'form_submit', { form_submit_id: docRef.id, form_name: 'contact' });
      dispatch(setStatus('message-sent-success'));
      dispatch(setSnackbarSuccess({ openSnackbar: true, snackbarMessage: `Message sent!` }));
      dispatch(resetScene());
    } catch (e) {
      console.error('Error adding document: ', e);
      dispatch(setStatus('message-sent-error'));
      dispatch(setSnackbarError({ openSnackbar: true, snackbarMessage: `Unable to send message` }));
    }
  };

export const contactSceneSlice = createSlice({
  name: 'contactscene',
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setMessageSubject: (state, action: PayloadAction<string>) => {
      state.messageSubject = action.payload;
    },
    setSenderName: (state, action: PayloadAction<string>) => {
      state.senderName = action.payload;
    },
    setSenderEmail: (state, action: PayloadAction<string>) => {
      state.senderEmail = action.payload;
    },
    setSenderPhone: (state, action: PayloadAction<string>) => {
      state.senderPhone = action.payload;
    },
    setStatus: (state, action: PayloadAction<ContactSceneStatusT>) => {
      state.status = action.payload;
    },
    resetScene: () => {
      return initialState;
    },
  },
});

export const { setMessage, setMessageSubject, setSenderName, setSenderEmail, setSenderPhone, setStatus, resetScene } =
  contactSceneSlice.actions;

export const getMessage = (state: RootState): string => state.contactscene.message;
export const getMessageSubject = (state: RootState): string => state.contactscene.messageSubject;
export const getSenderName = (state: RootState): string => state.contactscene.senderName;
export const getSenderEmail = (state: RootState): string => state.contactscene.senderEmail;
export const getSenderPhone = (state: RootState): string => state.contactscene.senderPhone;
export const getContactSceneMessage = (state: RootState): ContactSceneMessage => ({
  message: getMessage(state),
  messageSubject: getMessageSubject(state) || 'No subject',
  senderName: getSenderName(state) || 'Annonymous',
  senderEmail: getSenderEmail(state) || 'no email',
  senderPhone: getSenderPhone(state) || 'no phone',
});
export const getStatus = (state: RootState): ContactSceneStatusT => state.contactscene.status;

export default contactSceneSlice.reducer;
