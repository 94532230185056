import React from 'react';
import { createBrowserRouter, Link, redirect } from 'react-router-dom';
import { store } from 'app/store';

import App from './App';
import ErrorBoundary from 'components/error/ErrorBoundary';
import HomeDisplay from 'scenes/home/HomeDisplay';
import MainDisplay from 'scenes/home/subscenes/main/MainDisplay';

import {
  addProject,
  getProject,
  getProjects,
  isProjectLoadedById,
  projectsLoaded,
  setLoaded,
  setProjects,
} from 'scenes/project/projectsSlice';
import { setActiveScene } from 'scenes/scenesSlice';
import { updateUserStatus } from 'components/user/userSlice';
import { SceneType } from 'scenes/Scene.types';

import styles from 'App.module.scss';

import { getPeople, isPeopleLoaded, setPeople, addPersonToPeople, setPeopleLoaded } from 'scenes/aboutus/aboutUsSlice';
import {
  setTags,
  setTagsLoaded,
  getTags,
  isTagsLoaded,
  getActivePerson,
  getActivePortfolio,
  setActivePerson,
  setActivePortfolio,
  isActivePersonIdEquals,
  isActivePortfolioIdEquals,
  setActivePersonStatus,
} from 'scenes/aboutus/activeSlice';
import { IPerson, IPersonPortfolio } from 'scenes/aboutus/AboutUs.types';
import { IProject } from 'scenes/project/Projects.types';
import { fetchTags } from 'api/TagsAPI';
import { fetchAllPeople, fetchPerson } from 'api/PeopleAPI';
import { fetchPortfolio } from 'api/PortfolioAPI';
import { loadProject, loadProjects } from 'api/ProjectsAPI';
import { LogStyle } from 'app/constants';

const DesignDisplay = React.lazy(() => import('scenes/home/subscenes/design/DesignDisplay'));
const DevelopDisplay = React.lazy(() => import('scenes/home/subscenes/develop/DevelopDisplay'));
const TestDisplay = React.lazy(() => import('scenes/home/subscenes/test/TestDisplay'));
const AnalyseDisplay = React.lazy(() => import('scenes/home/subscenes/analyse/AnalyseDisplay'));
const ImproveDisplay = React.lazy(() => import('scenes/home/subscenes/improve/ImproveDisplay'));

const ProjectsDisplay = React.lazy(() => import('scenes/project/ProjectsDisplay'));
const ProjectsList = React.lazy(() => import('scenes/project/subscenes/main/ProjectsList'));
const ProjectDisplay = React.lazy(() => import('scenes/project/subscenes/project/ProjectDisplay'));

const ContactDisplay = React.lazy(() => import('scenes/contact/ContactDisplay'));

const AboutUsDisplay = React.lazy(() => import('scenes/aboutus/AboutUsDisplay'));
const AboutUsList = React.lazy(() => import('scenes/aboutus/AboutUsList'));
const AboutUsPerson = React.lazy(() => import('scenes/aboutus/AboutUsPerson'));
const PersonPortfolio = React.lazy(() => import('scenes/aboutus/subscenes/portfolio/PersonPortfolio'));
const EditPersonPortfolio = React.lazy(() => import('scenes/aboutus/subscenes/editportfolio/EditPersonPortfolio'));

const projectsLoader = async () => {
  store.dispatch(setActiveScene(SceneType.Projects));

  if (projectsLoaded(store.getState())) {
    return getProjects(store.getState());
  }

  const projects = await loadProjects();
  console.log('projectsLoader | Loaded projects');
  store.dispatch(setLoaded(true));
  store.dispatch(setProjects(projects));
  return projects;
};

const projectLoader = async ({ params }: any): Promise<IProject | undefined> => {
  if (isProjectLoadedById(store.getState(), params.projectId)) {
    return getProject(store.getState(), params.projectId);
  }

  const project = await loadProject(params.projectId);
  console.log(`projectLoader | Loaded project ${params.projectId}`);
  store.dispatch(addProject(project));
  return project;
};

const tagsLoader = async () => {
  if (isTagsLoaded(store.getState())) {
    return getTags(store.getState());
  }

  const tagList = await fetchTags();
  console.log('tagsLoader | Fetched tags');
  store.dispatch(setTags(tagList));
  store.dispatch(setTagsLoaded(true));
  return tagList;
};

const homeLoader = async () => {
  console.log('homeLoader | Loading HomeScene');
  store.dispatch(setActiveScene(SceneType.Home));
  return {};
};

const peopleLoader = async () => {
  console.log('peopleLoader | Loading About Us Scene');
  store.dispatch(setActiveScene(SceneType.AboutUs));

  await tagsLoader();
  if (isPeopleLoaded(store.getState())) {
    return getPeople(store.getState());
  }

  const people = await fetchAllPeople();
  console.log('peopleLoader | Fetched people');
  store.dispatch(setPeople(people));
  store.dispatch(setPeopleLoaded(true));
  return people;
};

const personLoader = async ({ params }: any) => {
  console.log(`%cpersonLoader %c| activePerson?.id=${store.getState().active.activePersonStatus} fetching id= ${params.personId}`, LogStyle.FCTitle, LogStyle.FCPDefaultText);
  if (isActivePersonIdEquals(store.getState(), params.personId)) {
    return getActivePerson(store.getState());
  }
  const person = await fetchPerson(`people/${params.personId}`);
  console.log(`personLoader | Fetched Person = ${params.personId}`);
  store.dispatch(setActivePerson({ ...person, status: 'loaded' }));
  store.dispatch(setActivePersonStatus('loaded'));
  store.dispatch(addPersonToPeople(person));
  return person;
};

const portfolioLoader = async ({ params }: any) => {
  console.log(`portfolioLoader | params.personId = ${params.personId}`);
  if (isActivePortfolioIdEquals(store.getState(), params.personId)) {
    return getActivePortfolio(store.getState());
  }

  const person: IPerson = (await personLoader({ params })) as IPerson;
  const portfolio: IPersonPortfolio = await fetchPortfolio(person);
  console.log(`portfolioLoader | Fetched Portfolio = ${params.personId}`);
  store.dispatch(setActivePortfolio({ ...portfolio, status: 'loaded' }));
  return portfolio;
};

const DISABLE_REDIRECT: boolean = true;
const authenticatedPortfolioLoader = async ({ params }: any) => {
  console.log(`authenticatedPortfolioLoader | params.personId = ${params.personId}`);
  const canViewEditContent = store.getState().user.userStatus === 'signedIn' && store.getState().user.isEditor;
  if (!DISABLE_REDIRECT && !canViewEditContent) {
    return redirect('/aboutus');
  }
  console.warn(`DISABLED ROUTE REDIRECT user state canViewEditContent=${canViewEditContent}`);
  return portfolioLoader({ params });
};

const router = createBrowserRouter(
  [
    {
      element: <App />,
      loader: () => {
        store.dispatch(updateUserStatus());
        return null;
      },
      errorElement: <ErrorBoundary />,
      children: [
        {
          id: 'route_home',
          path: '/',
          element: <HomeDisplay className={styles.AppSceneDisplay} />,
          errorElement: <ErrorBoundary />,
          loader: homeLoader,
          children: [
            { index: true, element: <MainDisplay /> },
            { path: 'design', element: <DesignDisplay /> },
            { path: 'develop', element: <DevelopDisplay />, errorElement: <ErrorBoundary /> },
            { path: 'test', element: <TestDisplay />, errorElement: <ErrorBoundary /> },
            { path: 'analyse', element: <AnalyseDisplay />, errorElement: <ErrorBoundary /> },
            { path: 'improve', element: <ImproveDisplay />, errorElement: <ErrorBoundary /> },
            // { path: '*', element: <MainDisplay />, errorElement: <ErrorBoundary /> },
          ],
        },
        {
          id: 'route_projects',
          element: <ProjectsDisplay className={styles.AppSceneDisplay} />,
          errorElement: <ErrorBoundary />,
          loader: projectsLoader,
          handle: {
            crumb: (data: Array<IProject>, isLast: boolean) =>
              isLast ? <span>Projects</span> : <Link to="/projects">Projects</Link>,
          },
          children: [
            { path: 'projects', element: <ProjectsList /> },
            {
              path: 'projects/:projectId',
              element: <ProjectDisplay />,
              loader: projectLoader,
              handle: {
                crumb: (data: IProject) => <span>{data.name}</span>,
              },
            },
            { path: '*', element: <ErrorBoundary />, errorElement: <ErrorBoundary /> },
          ],
        },
        {
          path: 'contact',
          element: <ContactDisplay className={styles.AppSceneDisplay} />,
          errorElement: <ErrorBoundary />,
          loader: () => {
            store.dispatch(setActiveScene(SceneType.Contact));
            return null;
          },
        },
        {
          id: 'route_aboutus',
          errorElement: <ErrorBoundary />,
          element: <AboutUsDisplay className={styles.AppSceneDisplay} />,
          loader: peopleLoader,
          handle: {
            crumb: (_data: Array<IPerson>, isLast: boolean) =>
              isLast ? <span>About Us</span> : <Link to="/aboutus">About Us</Link>,
          },
          children: [
            {
              path: 'aboutus',
              element: <AboutUsList />,
            },
            {
              path: 'aboutus/:personId',
              element: <AboutUsPerson />,
              loader: personLoader,
              // shouldRevalidate: () => {
              //   console.log(`called shouldRevalidate`);
              //   return shouldRevalidateActivePerson(store.getState());
              // },
              handle: {
                crumb: (data: IPerson, isLast: boolean) =>
                  isLast ? <span>{data.firstname}</span> : <Link to={`/aboutus/${data.id}`}>{data.firstname}</Link>,
              },
              children: [
                {
                  path: 'portfolio',
                  element: <PersonPortfolio />,
                  loader: portfolioLoader,
                  handle: {
                    crumb: (data: IPerson, isLast: boolean) =>
                      isLast ? (
                        <span>portfolio</span>
                      ) : (
                        <Link to=".." reloadDocument relative="path">
                          portfolio
                        </Link>
                      ),
                  },
                  children: [
                    {
                      path: 'edit',
                      element: <EditPersonPortfolio />,
                      loader: authenticatedPortfolioLoader,
                      handle: {
                        crumb: () => <span>edit</span>,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <App />,
    },
  ],
  {
    basename: '/',
  }
);

export default router;
