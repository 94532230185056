import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'components/header/Header';
import User from 'components/user/User';
import NotificationSnackbar from 'components/notification/NotificationSnackbar';
import SceneLoader from 'components/loader/SceneLoader';

import styles from 'App.module.scss';

const App: React.FC = () => {
  console.log('Calling App');

  return (
    <div className={styles.App}>
      <Header data-testid="app-header" onNavEvent={() => {}} />

      <main className={styles.AppScene}>
        <Suspense fallback={<SceneLoader className={styles.AppSceneDisplay} />}>
          <Outlet />
        </Suspense>
      </main>

      <NotificationSnackbar />
      <User />
      
    </div>
  );
};

export default App;
