import React from 'react';
import { Typography, TypographyT } from '@rmwc/typography';
import { ThemeOptionT } from '@rmwc/types';

import styles from './Logo.module.scss';

type LogoProps = {
  useStyle?: ThemeOptionT;
  useTypography?: TypographyT;
};

const Logo: React.FC<LogoProps> = ({ useStyle = 'onPrimary', useTypography = 'headline4' }: LogoProps) => {
  return (
    <div className={styles.LogoDisplay}>
      <Typography use={useTypography} theme={useStyle} className={styles.LogoDisplayText}>
        eleventh<span className={styles.LogoDisplayTextEye}>eye</span>
      </Typography>
    </div>
  );
};

export default Logo;
