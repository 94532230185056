export enum Category {
    Any = "Any",
    Error = "Error",
    Language = "Language",
    VersionControl = "VersionControl",
    Design = "Design",
    Database = "Database",
    Skill = "Skill",
    Framework = "Framework",
    Technology = "Technology",
    School = "School",
    Web = "Web",
    OperatingSystem = "OperatingSystem",
    Software = "Software",
    Employment = "Employment",
    Project = "Project"
};

export type CategoryName = keyof typeof Category;

export type PillModel = {
    pillId: string,
    type: Category,
    label: string,
    refs: string[]
};

export type PillState = {
    pillId: string,
    label: string,
    type: Category,
    highlighted: boolean,
    hidden: boolean
};

export type PillProps = {
    pillId: string
};

export type PillsProps = {
    pills: PillProps[],
    pillIds: string[],
    loadPills: () => void,
    onFilterQueryChange: (filter: string) => void,
    onFilterCategoryChange: (category: string) => void,
    loaded: boolean,
    loading: boolean,
    filterQuery: string | "",
    filterCategory: string
};

export type PillFilterProps = {
    onFilterQueryChange: (filter: string) => void,
    onFilterCategoryChange: (category: string) => void,
    filterQuery: string | "",
    filterCategory: string
};

export type PillsState = {
    pills: PillModel[],
    loaded: boolean,
    loading: boolean,
    filterQuery: string | "",
    filterCategory: Category,
    showFiltered: boolean,
    filter?: string
};


export type QueryFilterType = {
    query: string
};

export type CategoryFilterType = {
    category: Category
};

export type FilterType = QueryFilterType | CategoryFilterType;

export type Filter = {
    searchCategories: CategoryFilterType[],
    searchQuery: QueryFilterType
};


// UI Types

// Store Model Types