import React, { Suspense } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';
import SceneLoader from 'components/loader/SceneLoader';
import { SceneProps } from 'scenes/Scene.types';

import styles from './HomeDisplay.module.scss';

const HomeDisplay: React.FC<SceneProps> = (props: SceneProps) => {
  const IconNext = <Icon icon={{ icon: 'navigate_next', size: 'xsmall' }} />;
  const location = useLocation();

  const renderSubHeaderLinks = (words: Array<string>) => {
    return words.map((word, index) => (
      <div key={`headerWordTupple_${index}`} className={styles.HeaderOverlineText}>
        <Link to={`${word.toLowerCase()}`} state={`home:${word.toLowerCase()}`}>
          <Typography
            use="overline"
            theme={location.pathname.indexOf(word.toLowerCase()) > -1 ? 'onSecondary' : 'secondary'}
          >
            {word}
          </Typography>
          {words.length > index + 1 ? IconNext : ''}
        </Link>
      </div>
    ));
  };

  return (
    <div className={`${styles.HomeDisplay} ${props.className}`}>
      <div className={styles.HomeDisplayHeader}>
        <Typography use="overline" theme="onSecondary" className={styles.HeaderOverlineText}>
          {renderSubHeaderLinks(['Design', 'Develop', 'Test', 'Analyse', 'Improve'])}
        </Typography>
      </div>
      <Suspense fallback={<SceneLoader />}>
        <Outlet />
      </Suspense>
    </div>
  );
};

export default HomeDisplay;
