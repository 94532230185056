const Z_ELEVATION_ACTIVE: number = 7;
const Z_ELEVATION_INACTIVE: number = 0;
const Z_ELEVATION_HOVER: number = 5;

export const Z_ELEVATION = {
  ACTIVE: Z_ELEVATION_ACTIVE,
  INACTIVE: Z_ELEVATION_INACTIVE,
  HOVER: Z_ELEVATION_HOVER,
};
// Styles for DevTool Logs when any React.FC calls console.log
export const LogStyle = {
  FCTitle: 'color: #0097ED;font-weight: 600;',
  FCPDefaultText: 'color: #333333',
  FCAction: 'color: #AA97ED;font-weight: 500;',
  FCSuccess: 'color: #08A734;',
  FCFail: 'color: #FF5733;',
};
