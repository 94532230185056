import React from 'react';
import { Typography } from '@rmwc/typography';

import styles from './SceneLoader.module.scss';

const SceneLoader: React.FC<React.HTMLAttributes<HTMLAllCollection>> = (props) => {
  return (
    <div className={`${styles.SceneLoaderDisplay} ${props.className}`}>
      <Typography use="overline" theme="onSecondary">
        Loading...
      </Typography>
    </div>
  );
};

export default SceneLoader;
