import { db } from '../firebase/Firebase';
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { IPerson, PeopleMapT } from 'scenes/aboutus/AboutUs.types';

export const fetchAllPeople = async (): Promise<PeopleMapT> => {
  const q = query(collection(db, 'people'), orderBy('lastname', 'asc'));
  const querySnapshot = await getDocs(q);
  let peopleMap: PeopleMapT = {};
  // console.log(`fetchAllPeople count: ${querySnapshot.size}`);
  querySnapshot.docs.forEach((doc) => {
    const docData = doc.data();
    peopleMap[doc.id] = {
      id: doc.id,
      firstname: docData.firstname,
      lastname: docData.lastname,
      profession: docData.profession,
      avatar: docData.avatar,
    };
  });

  return peopleMap;
};

export const fetchPerson = async (personPath: string): Promise<IPerson> => {
  const docRef: DocumentReference<DocumentData> = doc(db, personPath);
  const docSnap: DocumentSnapshot<DocumentData> = await getDoc(docRef);

  if (docSnap.exists()) {
    const docData = docSnap.data();
    const person: IPerson = {
      id: docSnap.id,
      firstname: docData.firstname,
      lastname: docData.lastname,
      description: docData.description,
      profession: docData.profession,
      title: docData.title,
      avatar: docData.avatar,
      portfolioPath: docData.portfolioPath,
      hasPortfolio: !!docData.portfolioPath,
      status: 'loaded',
    };
    return person;
  } else {
    console.error('No such document!');
    throw Error(`Person does not exist for personPath=${personPath}`);
  }
};

export const updatePerson = async (personPath: string, update: Partial<IPerson>, userId: string): Promise<IPerson> => {
  const personRef = doc(db, personPath);
  await updateDoc(personRef, { ...update, modifiedBy: userId, modifiedAt: serverTimestamp() });
  const person = await fetchPerson(personPath);
  return person;
};
