import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogOnCloseEventT, DialogTitle } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';

import styles from './BaseDialog.module.scss';

type BaseDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClosedCallback?: () => void;
  onActionCallback?: (action: string) => void;
  preventOutsideDismiss?: boolean;
  title: string;
  content: React.ReactNode;
  actions: React.ReactNode | Array<React.ReactNode>;
};

const BaseDialog: React.FC<BaseDialogProps> = (props: BaseDialogProps) => {
  
  return (
    <Dialog
      className={styles.BaseDialog}
      autoFocus={false}
      open={props.open}
      preventOutsideDismiss={!!props.preventOutsideDismiss}
      onClose={(evt: DialogOnCloseEventT) => {
        // console.log(`BaseDialog onClose evt.target=${evt.detail.action}`);
        if (props.onActionCallback) {
          props.onActionCallback(evt.detail.action || 'unknown');
        }
        if (evt.detail.action === 'destroy' && props.onClosedCallback) {
          props.onClosedCallback();
        }
      }}
      onClosed={() => {
        // console.log(`BaseDialog onClosed`);
        if (props.onClosedCallback) {
          props.onClosedCallback();
        }
        if (props.open) {
          props.setOpen(false);
        }
      }}
    >
      <DialogTitle>
        <Typography theme="secondary" tag="span" use="overline">
          {props.title}
        </Typography>
      </DialogTitle>
      <DialogContent>{props.content}</DialogContent>
      <DialogActions>{props.actions}</DialogActions>
    </Dialog>
  );
};

export default BaseDialog;
