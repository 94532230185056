import { db } from '../firebase/Firebase';
import {
  arrayRemove,
  arrayUnion,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import {
  IEducation,
  IPerson,
  IPersonPortfolio,
  ISkill,
  ICategorySkill,
  IWork,
  ITag,
  IPortfolioProject,
  CategoryItemNamesMapT,
} from 'scenes/aboutus/AboutUs.types';
import { loadPortfolioProjects } from './PortfolioProjectsAPI';
import { loadEmploymentData } from './PortfolioEmploymentAPI';
import { loadEducationData } from './PortfolioEducationAPI';
import { loadSkillsData } from './PortfolioSkillsAPI';
import { updatePerson } from './PeopleAPI';

export const loadSkillsForCategory = async (
  portfolioId: string,
  categoryId: string,
  category: string
): Promise<Array<ICategorySkill>> => {
  let skillItems: Array<ICategorySkill> = [];
  const catRef = doc(db, `portfolio/${portfolioId}/${category}/${categoryId}`);
  const docSnap = await getDoc(catRef);

  if (docSnap.exists()) {
    skillItems = docSnap.data().skills;
  }

  return skillItems;
};

export const loadTagsForCategory = async (
  portfolioId: string,
  categoryId: string,
  category: string
): Promise<Array<string>> => {
  let tags: Array<string> = [];
  const catRef = doc(db, `portfolio/${portfolioId}/${category}/${categoryId}`);
  const docSnap = await getDoc(catRef);

  if (docSnap.exists()) {
    tags = docSnap.data().tags;
  }

  return tags;
};

export const addSkillToCategory = async (
  portfolioId: string,
  skill: ICategorySkill,
  category: string,
  categoryId: string,
  userUid: string
): Promise<Array<ICategorySkill>> => {
  const skillsRef = doc(db, `portfolio/${portfolioId}/${category}/${categoryId}`);
  await updateDoc(skillsRef, {
    skills: arrayUnion(skill),
    modifiedBy: userUid,
    modifiedAt: serverTimestamp(),
  });
  const skills = await loadSkillsForCategory(portfolioId, categoryId, category);

  return skills;
};

export const removeSkillFromCategory = async (
  portfolioId: string,
  skill: ICategorySkill,
  category: string,
  categoryId: string,
  userUid: string
): Promise<Array<ICategorySkill>> => {
  const skillsRef = doc(db, `portfolio/${portfolioId}/${category}/${categoryId}`);
  await updateDoc(skillsRef, {
    skills: arrayRemove(skill),
    modifiedBy: userUid,
    modifiedAt: serverTimestamp(),
  });
  const skills = await loadSkillsForCategory(portfolioId, categoryId, category);

  return skills;
};

export const addTagToCategory = async (
  portfolioId: string,
  category: string,
  categoryId: string,
  tag: ITag,
  userUid: string
): Promise<Array<string>> => {
  const tagsRef = doc(db, `portfolio/${portfolioId}/${category}/${categoryId}`);
  await updateDoc(tagsRef, {
    tags: arrayUnion(tag.id),
    modifiedBy: userUid,
    modifiedAt: serverTimestamp(),
  });
  const tags = await loadTagsForCategory(portfolioId, categoryId, category);

  return tags;
};

export const removeTagFromCategory = async (
  portfolioId: string,
  category: string,
  categoryId: string,
  tag: ITag,
  userUid: string
): Promise<Array<string>> => {
  const tagsRef = doc(db, `portfolio/${portfolioId}/${category}/${categoryId}`);
  await updateDoc(tagsRef, {
    tags: arrayRemove(tag.id),
    modifiedBy: userUid,
    modifiedAt: serverTimestamp(),
  });
  const tags = await loadTagsForCategory(portfolioId, categoryId, category);

  return tags;
};

export const updateLanguage = async (
  portfolioId: string,
  languages: Array<string>,
  userUid: string
): Promise<Array<string>> => {
  const languageRef = doc(db, `portfolio/${portfolioId}`);
  await updateDoc(languageRef, {
    languages: languages,
    modifiedBy: userUid,
    modifiedAt: serverTimestamp(),
  });

  return languages;
};

export const updateAdditionalInfo = async (
  portfolioId: string,
  info: Array<string>,
  userUid: string
): Promise<Array<string>> => {
  const additionalInfoRef = doc(db, `portfolio/${portfolioId}`);
  await updateDoc(additionalInfoRef, {
    additionalInfo: info,
    modifiedBy: userUid,
    modifiedAt: serverTimestamp(),
  });

  return info;
};

export const fetchPortfolio = async (person: IPerson): Promise<IPersonPortfolio> => {
  const docRef: DocumentReference<DocumentData> = doc(db, person.portfolioPath);
  const docSnap: DocumentSnapshot<DocumentData> = await getDoc(docRef);

  if (docSnap.exists()) {
    const educationList: Array<IEducation> = await loadEducationData(docSnap.id);
    const employmentList: Array<IWork> = await loadEmploymentData(docSnap.id);
    const skillList: Array<ISkill> = await loadSkillsData(docSnap.id);
    const projects: Array<IPortfolioProject> = await loadPortfolioProjects(docSnap.id);

    const educationNames = educationList.map((item) => ({ id: item.id, name: item.name }));
    const employmentNames = employmentList.map((item) => ({ id: item.id, name: item.name }));
    const projectNames = projects.map((item) => ({ id: item.id, name: item.name }));
    const allCategoryItemNames = [...educationNames, ...employmentNames, ...projectNames];

    let categoryItemNames: CategoryItemNamesMapT = {};
    allCategoryItemNames.forEach((item) => {
      categoryItemNames[item.id] = item.name;
    });

    const docData = docSnap.data();
    const personPortfolio: IPersonPortfolio = {
      ...person,
      id: docSnap.id,
      email: docData.email,
      firstname: docData.firstname,
      lastname: docData.lastname,
      profession: docData.profession,
      languages: docData.languages,
      additionalInfo: docData.additionalInfo,
      education: [...educationList],
      employment: [...employmentList],
      projects: [...projects],
      skills: [...skillList],
      hasPortfolio: true,
      status: 'loaded',
      categoryItemNamesById: categoryItemNames,
    };

    return personPortfolio;
  } else {
    console.error('No such document!');
    throw Error(`Portfolio does not exist for portfolioPath=${person.portfolioPath}`);
  }
};

export const createPortfolio = async (person: IPerson, userUid: string): Promise<IPerson> => {
  const id = person.id;

  await setDoc(doc(db, 'portfolio', id), {
    email: 'email',
    firstname: person.firstname,
    lastname: person.lastname,
    profession: person.profession,
    languages: [],
    additionalInfo: [],
    createdAt: serverTimestamp(),
    createdBy: userUid,
  });

  const updatedPerson = await updatePerson(`people/${id}`, {portfolioPath: `portfolio/${id}`}, userUid);
  return updatedPerson;
};
