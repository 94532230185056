import React, { useState } from 'react';
import { TextField } from '@rmwc/textfield';
import { Elevation } from '@rmwc/elevation';

import styles from './BaseInputField.module.scss';

export type BaseInputFieldProps = {
  id: string;
  type: string;
  label: string;
  value: string;
  onChange: (newValue: string) => void;
  onFocusFn?: (e: any) => void;
  isTextarea?: boolean;
  icon?: string;
  disabled?: boolean;
  autocomplete?: string;
  trailingIcon?: string;
  onTrailingIconClick?: () => void;
  floatLabel?: boolean;
} & Omit<React.HTMLAttributes<HTMLAllCollection>, 'onChange'> &
  Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange'>;

const Z_ELEVATION_ACTIVE: number = 7;
const Z_ELEVATION_INACTIVE: number = 0;
const Z_ELEVATION_HOVER: number = 3;

const BaseInputField: React.FC<BaseInputFieldProps> = ({
  id,
  type,
  label,
  value,
  onChange,
  onFocusFn,
  onTrailingIconClick,
  icon,
  trailingIcon = '',
  isTextarea = false,
  disabled = false,
  autocomplete = 'new-password',
  floatLabel = true,
  ...props
}: BaseInputFieldProps) => {
  const [fieldElevation, setFieldElevation] = useState<number>(Z_ELEVATION_INACTIVE);
  const numRows = value.length > 200 ? 6 : 4; 

  const textAreaProps = isTextarea
    ? {
        rows: numRows,
        maxLength: 500,
        characterCount: true,
        resizeable: false,
        textarea: true,
      }
    : { textarea: false };

  const setFieldElevationOnMouseEvent = (isMouseOver: boolean) => {
    const elevation =
      fieldElevation === Z_ELEVATION_ACTIVE
        ? Z_ELEVATION_ACTIVE
        : isMouseOver
        ? Z_ELEVATION_HOVER
        : Z_ELEVATION_INACTIVE;
    setFieldElevation(elevation);
  };

  const trailingIconProps =
    trailingIcon.length > 0
      ? {
          icon: trailingIcon,
          style: { opacity: `${value.length > 0 ? 1 : 0}` },
          tabIndex: 0,
          onClick: () => {
            typeof onTrailingIconClick === 'function' ? onTrailingIconClick() : onChange('');
          },
        }
      : false;

  return (
    <Elevation
      id={`baseInputField_${id}`}
      transition
      className={`${styles.BaseInputField} ${styles.BaseInputFieldElevation}`}
      onClick={props.onClick}
      z={fieldElevation}
    >
      <TextField
        id={`not-for-chrome-${id}`}
        outlined
        {...props}
        icon={icon}
        disabled={disabled}
        name={`not-for-chrome-${id}`}
        autoComplete={autocomplete}
        form={(autocomplete = 'off')}
        type={type}
        floatLabel={floatLabel}
        label={label}
        value={value}
        onChange={(e: any) => onChange(e.currentTarget.value)}
        onFocus={(e: any) => {
          setFieldElevation(disabled ? Z_ELEVATION_INACTIVE : Z_ELEVATION_ACTIVE);
          if (onFocusFn) {
            console.log(`calling scroll up`);
            onFocusFn(e);
          }
        }}
        onBlur={() => setFieldElevation(Z_ELEVATION_INACTIVE)}
        onMouseOver={() => setFieldElevationOnMouseEvent(!disabled)}
        onMouseOut={() => setFieldElevationOnMouseEvent(false)}
        {...textAreaProps}
        trailingIcon={trailingIconProps}
      />
    </Elevation>
  );
};

export default BaseInputField;
