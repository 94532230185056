import { db } from '../firebase/Firebase';
import {
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { FBUser, IUser } from 'components/user/userSlice';
import { User } from 'firebase/auth';

const getFBUserModel = (uid: string, firstname: string, lastname: string, email: string, editor: boolean): FBUser => {
  const user: FBUser = { uid, firstname, lastname, email, editor };
  return user;
};

const getUserModel = (uid: string, firstname: string, lastname: string, email: string, isEditor: boolean): IUser => {
  const user: IUser = { uid, firstname, lastname, email, isEditor };
  return user;
};

// TODO: Fetch all available users
// const getAvailableUsers = async (): Promise<Array<IUser>> => {


// };

export const fetchUserInfo = async (userUid: string): Promise<IUser> => {
  const docRef: DocumentReference<DocumentData> = doc(db, `users/${userUid}`);
  const docSnap: DocumentSnapshot<DocumentData> = await getDoc(docRef);

  if (docSnap.exists()) {
    const docData = docSnap.data();
    const user: IUser = {
      uid: docSnap.id,
      firstname: docData.firstname,
      lastname: docData.lastname,
      email: docData.email,
      isEditor: docData.editor,
    };
    return user;
  } else {
    console.log('No such document!');
    const user: IUser = getUserModel(docSnap.id, 'User', 'Annonymous', 'annonymous, no email', false);
    return user;
    // throw Error(`Person does not exist for personPath=/users/${userUid}`);
  }
};

export const updateUserAccount = async (modUserUid: string, user: IUser): Promise<IUser> => {
  const userRef = doc(db, `users/${user.uid}`);
  await updateDoc(userRef, {
    editor: user.isEditor,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    modifiedAt: serverTimestamp(),
    modifiedBy: modUserUid,
  });

  return fetchUserInfo(user.uid);
};

export const createUserRecordOnUserAccountCreation = async (fbUser: User): Promise<FBUser> => {
  const userUid = fbUser.uid;
  const user: FBUser = getFBUserModel(userUid, '', '', fbUser.email || '', false);
  await setDoc(doc(db, 'users', userUid), { ...user, createdAt: serverTimestamp(), createdBy: userUid });

  return user;
};
