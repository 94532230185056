import { Typography } from '@rmwc/typography';
import React from 'react';

import styles from './Quote.module.scss';

type QuoteProps = {
  cite: string;
  quote: string;
  author: string;
};

const Quote: React.FC<QuoteProps> = ({ cite, quote, author }: QuoteProps) => {
  return (
    <Typography use="body1" tag="div" theme="textPrimaryOnBackground" className={styles.QuoteDisplay}>
      <figure className={styles.QuoteFigure}>
        <blockquote cite={cite} className={styles.QuoteBlockquote}>
          <p>{quote}</p>
          <figcaption>{`—${author}`}</figcaption>
        </blockquote>
      </figure>
    </Typography>
  );
};

export default Quote;
