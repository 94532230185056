import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { Dictionary, SceneItem, SceneType } from 'scenes/Scene.types';
import * as SceneHelper from 'scenes/Scene.helpers';
import { IconSizeT } from '@rmwc/types';

type SnackbarT = 'success' | 'error' | 'info';

export interface ScenesState {
  activeScene: SceneType;
  allScenes: Dictionary<SceneItem>;
  snackbarOpen: boolean;
  snackbarMessage: string;
  snackbarType: SnackbarT;
  showHeaderText: boolean;
  headerSize: IconSizeT;
}

const initialState: ScenesState = {
  activeScene: SceneType.Home,
  allScenes: SceneHelper.allScenes(),
  snackbarMessage: '',
  snackbarOpen: false,
  snackbarType: 'info',
  showHeaderText: false,
  headerSize: 'small',
};

type SnackbarAction = {
  snackbarMessage: string;
  openSnackbar: boolean;
};

export const scenesSlice = createSlice({
  name: 'scenes',
  initialState,
  reducers: {
    setActiveScene: (state, action: PayloadAction<SceneType>) => {
      state.activeScene = action.payload;
    },
    setSnackbarOpen: (state, action: PayloadAction<boolean>) => {
      state.snackbarOpen = action.payload;
    },
    setSnackbar: (state, action: PayloadAction<SnackbarAction>) => {
      state.snackbarMessage = action.payload.snackbarMessage;
      state.snackbarOpen = action.payload.openSnackbar;
      state.snackbarType = 'info';
    },
    setSnackbarError: (state, action: PayloadAction<SnackbarAction>) => {
      state.snackbarMessage = action.payload.snackbarMessage;
      state.snackbarOpen = action.payload.openSnackbar;
      state.snackbarType = 'error';
    },
    setSnackbarSuccess: (state, action: PayloadAction<SnackbarAction>) => {
      state.snackbarMessage = action.payload.snackbarMessage;
      state.snackbarOpen = action.payload.openSnackbar;
      state.snackbarType = 'success';
    },
    setShowHeaderText: (state, action: PayloadAction<boolean>) => {
      state.showHeaderText = action.payload;
    },
    setHeaderSize: (state, action: PayloadAction<IconSizeT>) => {
      state.headerSize = action.payload;
    },
  },
});

export const {
  setActiveScene,
  setSnackbarOpen,
  setSnackbar,
  setSnackbarError,
  setSnackbarSuccess,
  setShowHeaderText,
  setHeaderSize,
} = scenesSlice.actions;

export const getActiveScene = (state: RootState): SceneType => state.scenes.activeScene;
export const getAllScenes = (state: RootState): Dictionary<SceneItem> => state.scenes.allScenes;
export const isSnackbarOpen = (state: RootState): boolean => state.scenes.snackbarOpen;
export const getSnackbarMessage = (state: RootState): string => state.scenes.snackbarMessage;
export const getSnackbarType = (state: RootState): SnackbarT => state.scenes.snackbarType;
export const isShowHeaderText = (state: RootState): boolean => state.scenes.showHeaderText;
export const getHeaderSize = (state: RootState) => state.scenes.headerSize;
export const isActiveScene = (state: RootState, sceneType: SceneType) => state.scenes.activeScene === sceneType;
export const getActiveSceneModel: (state: RootState) => SceneItem = createSelector(
  getActiveScene,
  getAllScenes,
  (activeSceneType, sceneList) => {
    return sceneList[activeSceneType];
  }
);
export const getActiveSceneHeaderTitle: (state: RootState) => string = createSelector(getActiveSceneModel, (scene) => {
  return scene && scene.header ? scene.header.title : 'Unknown';
});
export const getActiveSceneHeaderActionItems: (state: RootState) => SceneItem[] = createSelector(
  getActiveSceneModel,
  getAllScenes,
  (scene, sceneList) => {
    const actionItemTypes = scene && scene.header ? scene.header.actionItems : [];
    const actionItems: SceneItem[] = actionItemTypes.map((type: SceneType) => {
      return sceneList[type];
    });

    return actionItems;
  }
);

export default scenesSlice.reducer;
