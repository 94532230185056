import { storage, getDownloadURL } from '../firebase/Firebase';
import { listAll, ref, StorageReference, uploadBytes, uploadBytesResumable, UploadResult } from 'firebase/storage';
import { IStorageItem } from 'scenes/aboutus/Portfolio.types';
// import { sortIStorageItems } from 'app/helperFunctions';

export const getAllPortfolioImages = async (path: string): Promise<Array<IStorageItem>> => {
  const allRefs = await fetchAllStorageRefs(path);
  // const fileRefs = allRefs.filter((storeItem) => storeItem.isFolder !== true) || [];
  // const fileUrlRefs = fileRefs.map((img) => img.urlRef as string) || [];
  const downloadUrls = await fetchDownloadUrlsForFileRefs({ imageRefs: allRefs, markSelected: false });
  return downloadUrls;
};

export const fetchAllStorageRefs = async (path: string): Promise<Array<IStorageItem>> => {
  const imagesRef = ref(storage, path);
  let urlList: Array<IStorageItem> = [];
  urlList = await listAll(imagesRef)
    .then((res) => {
      res.prefixes.forEach((folderRef:StorageReference) => {
        console.log(`folderRef, ${folderRef}`);
        urlList.push({ name: folderRef.name, urlRef: folderRef.fullPath, isFolder: true });
      });

      res.items.forEach((itemRef) => {
        // console.log(`Images in portfolio storage bucket, ${itemRef.fullPath}`);
        // if (itemRef.name.indexOf('.png') === -1) {
          urlList.push({ name: itemRef.name, urlRef: itemRef.fullPath, isFolder: false, selected: false });
        // }
      });
      return urlList;
    })
    .catch((error) => {
      console.error(`Error occurred when tried to get images in portfolio storage bucket, ${error}`);
      return [];
    })
    .finally(() => {
      return urlList;
    });
  console.log(`Returning urlList ${urlList.length}`);
  return urlList;
};

type FetchUrlProps = {
  imageRefs: Array<IStorageItem>;
  markSelected?: boolean;
};

export const fetchDownloadUrlsForFileRefs = async (props: FetchUrlProps): Promise<Array<IStorageItem>> => {
  const { imageRefs, markSelected = false } = props;
  const fileUrls = await Promise.all(
    imageRefs.map(async (itemRef) => {
      if (itemRef.isFolder) {
        return { ...itemRef, url: '/images/logo-codepen.png' };
      }
      const url = await getDownloadURL(ref(storage, itemRef.urlRef as string));
      return { ...itemRef, url: url, selected: markSelected };
    })
  );
  return fileUrls;
};

export const uploadFiles = async (files: Array<File>, uid: string, folder: string) => {
  const fileRefs: Array<IStorageItem> = await Promise.all(
    files.map(async (file: File) => {
      const fileRef = await upload(file, uid, folder);
      // return { name: fileRef.name, urlRef: fileRef.fullPath, isFolder: false, selected: true };
      return { name: fileRef.name, urlRef: fileRef.fullPath, isFolder: false, selected: false };
    })
  );

  const newImageUrls: Array<IStorageItem> = await fetchDownloadUrlsForFileRefs({
    imageRefs: fileRefs,
    markSelected: false,
  });
  return newImageUrls;
};

const upload = async (file: File, uid: string, folder: string): Promise<StorageReference> => {
  const fileName = `images/${uid}/${folder}/${file.name}`;
  const storageRef = ref(storage, fileName);

  const uploadResult: UploadResult = await uploadBytes(storageRef, file);
  return uploadResult.ref;
};

export const uploadFile = (file: File, uid: string, folder: string, callbackFn: (url: string) => void) => {
  const fileName = `images/${uid}/${folder}/${file.name}`;
  const storageRef = ref(storage, fileName);

  const uploadTask = uploadBytesResumable(storageRef, file);

  // Register three observers:
  // 1. 'state_changed' observer, called any time the state changes
  // 2. Error observer, called on failure
  // 3. Completion observer, called on successful completion
  uploadTask.on(
    'state_changed',
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    },
    (error) => {
      // Handle unsuccessful uploads
      console.log('Error File ', error);
    },
    () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        console.log('File available at', downloadURL);
        callbackFn(downloadURL);
      });
    }
  );
};
