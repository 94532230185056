import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchResults, MaybeSelectedItem, ResultItemT } from 'App.types';
import { AppThunk, RootState } from 'app/store';
import {
  IEducation,
  IPerson,
  IPersonBasicInfo,
  IPersonPortfolio,
  ISkill,
  ICategorySkill,
  ITag,
  IWork,
  PeopleMapT,
  StatusT,
  IPortfolioProject,
  CategoryItemId,
} from './AboutUs.types';

import { getActivePortfolio, getTags } from './activeSlice';

export interface PeopleState {
  people: PeopleMapT;
  peopleLoaded: boolean;
}

export interface TagsState {
  tags?: Array<ITag>;
  tagsLoaded: boolean;
}

export interface SearchState {
  searchQuery?: string;
  skillSearchResult?: Array<string>;
  searchResults: Array<ISearchResults>;
}

export interface ActiveState {
  activePerson?: IPerson;
  activePortfolio?: IPersonPortfolio;
}

export interface SkillDialogState {
  showAddSkillDialog: boolean;
  showUpdateSkillDialog: boolean;
  skillDialogWriteType: DialogWriteType;
  selectedSkillToEdit: ISkill | undefined;
}
export interface TagDialogState {
  tagDialogWriteType: DialogWriteType;
  showAddTagDialog: boolean;
  showUpdateTagDialog: boolean;
  selectedTagToEdit: ITag | undefined;
}

export interface ProjectDialogState {
  projectDialogWriteType: DialogWriteType;
  showAddProjectDialog: boolean;
  showUpdateProjectDialog: boolean;
  selectedProjectToEdit: IPortfolioProject | undefined;
}

export interface DialogStates extends SkillDialogState, TagDialogState, ProjectDialogState {}

export interface AboutState extends PeopleState, TagsState, SearchState, ActiveState, DialogStates {}

export type DialogWriteType = 'CREATE' | 'EDIT';

const initialState: AboutState = {
  people: {},
  peopleLoaded: false,
  tags: [],
  tagsLoaded: false,
  searchQuery: '',
  skillSearchResult: [],
  searchResults: [],
  activePerson: undefined,
  activePortfolio: undefined,
  showAddSkillDialog: false,
  showUpdateSkillDialog: false,
  showAddTagDialog: false,
  showUpdateTagDialog: false,
  showAddProjectDialog: false,
  showUpdateProjectDialog: false,
  skillDialogWriteType: 'CREATE',
  tagDialogWriteType: 'CREATE',
  projectDialogWriteType: 'CREATE',
  selectedSkillToEdit: undefined,
  selectedTagToEdit: undefined,
  selectedProjectToEdit: undefined,
};

// const isEmptyParams = (portfolioId: string, skillId: string, tagId: string): boolean => {
//   return isEmptyString(tagId) || isEmptyString(skillId) || isEmptyString(portfolioId);
// };

export type DocId = string;
export type MatchedDocs = Record<DocId, ResultItemT>;

export const doPortfolioSearch =
  (searchString: string, portfolioId: string): AppThunk =>
  (dispatch, getState) => {
    if (searchString) {
      let cleanSearchQuery = searchString.trim();
      let searchResults: Array<ISearchResults> = [];

      const portfolio = getActivePortfolio(getState()) as IPersonPortfolio;
      const tags = getTags(getState()) as Array<ITag>;
      const matchedTags = tags.filter((tag) => tag.name.toLowerCase().indexOf(cleanSearchQuery.toLowerCase()) > -1);
      const matchedTagIds: Array<string> = matchedTags.map((t) => t.id);
      const matchedTagNames: Array<string> = matchedTags.map((t) => t.name);

      searchResults.push({ type: 'tag', results: [...matchedTags] });
      console.log(`search: ${cleanSearchQuery} found ${matchedTags.length} tag(s): ${matchedTagNames.toString()}`);
      // const possibleMatches =
      //   portfolio.skills?.filter((skill) => skill.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1) || [];
      const containsTag = (tags: Array<string>) => tags.filter((tag) => matchedTagIds.includes(tag)).length > 0;
      const matchSkillsPredicate = (skill: ISkill): boolean => containsTag(skill.tags || []);

      const possibleMatches = portfolio.skills?.filter(matchSkillsPredicate) || [];
      console.log(`possibleMatches: ${possibleMatches.map((pm) => pm.name).toString()}`);

      searchResults.push({ type: 'skill', results: [...possibleMatches] });
      // TODO: Get employment, eduction matches
      const containsSkill = (skills: Array<ICategorySkill>) =>
        skills.filter((skill) => possibleMatches.findIndex((s) => s.id === skill.skillId) > -1).length > 0;
      const matchEmploymentBySkillPredicate = (work: IWork): boolean => containsSkill(work.skills || []);
      const matchEmploymentByTagPredicate = (work: IWork): boolean => containsTag(work.tags || []);
      const possibleEmploymentSkillMatches = portfolio.employment?.filter(matchEmploymentBySkillPredicate) || [];
      const possibleEmploymentTagMatches = portfolio.employment?.filter(matchEmploymentByTagPredicate) || [];
      let employementMatch: MatchedDocs = {};

      possibleEmploymentSkillMatches.forEach((work) => {
        employementMatch[work.id] = { id: work.id, name: work.name, matchedBySkill: true };
      });

      possibleEmploymentTagMatches.forEach((work) => {
        if (employementMatch[work.id]) {
          employementMatch[work.id].matchedByTag = true;
        } else {
          employementMatch[work.id] = { id: work.id, name: work.name, matchedByTag: true };
        }
      });
      const employementMatchArray = Object.values(employementMatch) as Array<ResultItemT>;
      searchResults.push({ type: 'work', results: [...employementMatchArray] });
      const matchEducationBySkillPredicate = (work: IEducation): boolean => containsSkill(work.skills || []);
      const matchEducationByTagPredicate = (work: IEducation): boolean => containsTag(work.tags || []);
      const possibleEducationSkillMatches = portfolio.education?.filter(matchEducationBySkillPredicate) || [];
      const possibleEducationTagMatches = portfolio.education?.filter(matchEducationByTagPredicate) || [];
      let schoolMatch: MatchedDocs = {};

      possibleEducationSkillMatches.forEach((school) => {
        schoolMatch[school.id] = { id: school.id, name: school.name, matchedBySkill: true };
      });

      possibleEducationTagMatches.forEach((school) => {
        if (schoolMatch[school.id]) {
          schoolMatch[school.id].matchedByTag = true;
        } else {
          schoolMatch[school.id] = { id: school.id, name: school.name, matchedByTag: true };
        }
      });
      const schoolMatchArray = Object.values(schoolMatch) as Array<ResultItemT>;
      searchResults.push({ type: 'education', results: [...schoolMatchArray] });

      dispatch(setSkillSearchResult(possibleMatches ? possibleMatches.map((skill) => skill.name) : []));
      dispatch(setSearchResults(searchResults));
    } else {
      dispatch(setSkillSearchResult([]));
      dispatch(setSearchResults([]));
    }
    dispatch(setSearchQuery(searchString));
  };

// type SetCategoryDocumentTagsType = {
//   tags: Array<string>;
//   category: string;
//   categoryId: string;
// };
type CategoryNameItemType = {
  id: CategoryItemId;
  name: string;
};

export const aboutUsSlice = createSlice({
  name: 'aboutus',
  initialState,
  reducers: {
    setPeople: (state, action: PayloadAction<PeopleMapT>) => {
      state.people = action.payload;
    },
    setPeopleLoaded: (state, action: PayloadAction<boolean>) => {
      state.peopleLoaded = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    setSkillSearchResult: (state, action: PayloadAction<Array<string>>) => {
      state.skillSearchResult = action.payload;
    },
    setSearchResults: (state, action: PayloadAction<Array<ISearchResults>>) => {
      state.searchResults = action.payload;
    },
    setTags: (state, action: PayloadAction<Array<ITag>>) => {
      state.tags = action.payload;
    },
    setTagsLoaded: (state, action: PayloadAction<boolean>) => {
      state.tagsLoaded = action.payload;
    },
    addPersonToPeople: (state, action: PayloadAction<IPersonBasicInfo>) => {
      state.people[action.payload.id] = action.payload;
    },
    setCategoryName: (state, action: PayloadAction<CategoryNameItemType>) => {
      (state.activePortfolio as IPersonPortfolio).categoryItemNamesById[action.payload.id] = action.payload.name;
    },
    // setActivePortfolio: (state, action: PayloadAction<IPersonPortfolio>) => {
    //   state.activePortfolio = action.payload;
    // },
    // setActivePortfolioStatus: (state, action: PayloadAction<StatusT>) => {
    //   (state.activePortfolio as IPersonPortfolio).status = action.payload;
    // },
    setActivePerson: (state, action: PayloadAction<IPerson>) => {
      state.activePerson = action.payload;
    },
    setActivePersonStatus: (state, action: PayloadAction<StatusT>) => {
      (state.activePerson as IPerson).status = action.payload;
    },
    setSkillsToActivePortfolio: (state, action: PayloadAction<Array<ISkill>>) => {
      (state.activePortfolio as IPersonPortfolio).skills = action.payload;
    },
    // setActivePortfolioEducationCategory: (state, action: PayloadAction<Array<IEducation>>) => {
    //   (state.activePortfolio as IPersonPortfolio).education = action.payload;
    // },
    // setActivePortfolioEmploymentCategory: (state, action: PayloadAction<Array<IWork>>) => {
    //   (state.activePortfolio as IPersonPortfolio).employment = action.payload;
    // },
    // setActivePortfolioProjects: (state, action: PayloadAction<Array<IPortfolioProject>>) => {
    //   (state.activePortfolio as IPersonPortfolio).projects = action.payload;
    // },
    // setActivePortfolioEmploymentDocument: (state, action: PayloadAction<Array<IWork>>) => {
    //   (state.activePortfolio as IPersonPortfolio).employment = action.payload;
    // },
    // setActivePortfolioCategoryDocumentTags: (state, action: PayloadAction<SetCategoryDocumentTagsType>) => {
    //   const portfolio = state.activePortfolio as IPersonPortfolio;

    //   if (action.payload.category === 'employment') {
    //     const employmentList = portfolio.employment as Array<IWork>;
    //     (state.activePortfolio as IPersonPortfolio).employment = employmentList.map((work) => {
    //       if (work.id === action.payload.categoryId) {
    //         return { ...work, tags: action.payload.tags };
    //       }
    //       return { ...work };
    //     });
    //   } else if (action.payload.category === 'projects') {
    //     const projectList = portfolio.projects as Array<IPortfolioProject>;
    //     (state.activePortfolio as IPersonPortfolio).projects = projectList.map((project) => {
    //       if (project.id === action.payload.categoryId) {
    //         return { ...project, tags: action.payload.tags };
    //       }
    //       return { ...project };
    //     });
    //   } else {
    //     const educationList = portfolio.education as Array<IEducation>;
    //     (state.activePortfolio as IPersonPortfolio).education = educationList.map((school) => {
    //       if (school.id === action.payload.categoryId) {
    //         return { ...school, tags: action.payload.tags };
    //       }
    //       return { ...school };
    //     });
    //   }
    // },
    // setActivePortfolioLanguages: (state, action: PayloadAction<Array<string>>) => {
    //   (state.activePortfolio as IPersonPortfolio).languages = action.payload;
    // },
    // setActivePortfolioAdditionalInfo: (state, action: PayloadAction<Array<string>>) => {
    //   (state.activePortfolio as IPersonPortfolio).additionalInfo = action.payload;
    // },
    setShowAddProjectDialog: (state, action: PayloadAction<boolean>) => {
      state.showAddProjectDialog = action.payload;
      // state.projectDialogWriteType = action.payload === true ? 'CREATE' : state.projectDialogWriteType;
    },
    setShowUpdateProjectDialog: (state, action: PayloadAction<boolean>) => {
      // state.projectDialogWriteType = action.payload === true ? 'EDIT' : state.projectDialogWriteType;
      state.showUpdateProjectDialog = action.payload;
    },
    setProjectDialogWriteType: (state, action: PayloadAction<DialogWriteType>) => {
      state.projectDialogWriteType = action.payload;
    },
    setSelectedProjectToEdit: (state, action: PayloadAction<IPortfolioProject>) => {
      state.selectedProjectToEdit = action.payload;
    },
    setShowAddSkillDialog: (state, action: PayloadAction<boolean>) => {
      // state.skillDialogWriteType = action.payload === true ? 'CREATE' : state.skillDialogWriteType;
      state.showAddSkillDialog = action.payload;
    },
    setShowUpdateSkillDialog: (state, action: PayloadAction<boolean>) => {
      state.showUpdateSkillDialog = action.payload;
      // state.skillDialogWriteType = action.payload === true ? 'EDIT' : state.skillDialogWriteType;
    },
    setSkillDialogWriteType: (state, action: PayloadAction<DialogWriteType>) => {
      state.skillDialogWriteType = action.payload;
    },
    setSelectedSkillToEdit: (state, action: PayloadAction<ISkill>) => {
      state.selectedSkillToEdit = action.payload;
    },
    setShowAddTagDialog: (state, action: PayloadAction<boolean>) => {
      state.showAddTagDialog = action.payload;
    },
    setShowUpdateTagDialog: (state, action: PayloadAction<boolean>) => {
      state.showUpdateTagDialog = action.payload;
    },
    setTagDialogWriteType: (state, action: PayloadAction<DialogWriteType>) => {
      state.tagDialogWriteType = action.payload;
    },
    setSelectedTagToEdit: (state, action: PayloadAction<ITag>) => {
      state.selectedTagToEdit = action.payload;
    },
  },
});

export const {
  setPeople,
  addPersonToPeople,
  setPeopleLoaded,
  setSearchQuery,
  setSkillSearchResult,
  setSearchResults,
  // Skill Dialog
  setShowAddSkillDialog,
  setShowUpdateSkillDialog,
  setSkillDialogWriteType,
  setSelectedSkillToEdit,
  // Tag Dialog
  setSelectedTagToEdit,
  setShowAddTagDialog,
  setShowUpdateTagDialog,
  setTagDialogWriteType,
  // Project Dialog
  setShowAddProjectDialog,
  setShowUpdateProjectDialog,
  setProjectDialogWriteType,
  setSelectedProjectToEdit,
} = aboutUsSlice.actions;

export const isPeopleLoaded = (state: RootState): boolean => state.aboutus.peopleLoaded;
export const getPeople = (state: RootState) => state.aboutus.people;
export const isPersonExist = (state: RootState, id: string) => !!getPeople(state)[id];
export const getPersonById = (state: RootState, id: string) => getPeople(state)[id] || false;

export const getSearchQuery = (state: RootState): string => state.aboutus.searchQuery || '';
export const getSkillSearchResults = (state: RootState): Array<string> => state.aboutus.skillSearchResult || [];
export const getSearchResults = (state: RootState): Array<ISearchResults> => state.aboutus.searchResults || [];

export const showAddSkillDialog = (state: RootState) => state.aboutus.showAddSkillDialog;
export const showUpdateSkillDialog = (state: RootState) => state.aboutus.showUpdateSkillDialog;
export const getSkillDialogWriteType = (state: RootState) => state.aboutus.skillDialogWriteType;
export const getSelectedSkillToEdit = (state: RootState) => state.aboutus.selectedSkillToEdit;

export const showAddTagDialog = (state: RootState) => state.aboutus.showAddTagDialog;
export const showUpdateTagDialog = (state: RootState) => state.aboutus.showUpdateTagDialog
export const getTagDialogWriteType = (state: RootState) => state.aboutus.tagDialogWriteType;
export const getSelectedTagToEdit = (state: RootState) => state.aboutus.selectedTagToEdit;

export const showAddProjectDialog = (state: RootState) => state.aboutus.showAddProjectDialog;
export const showUpdateProjectDialog = (state: RootState) => state.aboutus.showUpdateProjectDialog;
export const getProjectDialogWriteType = (state: RootState) => state.aboutus.projectDialogWriteType;
export const getSelectedProjectToEdit = (state: RootState): MaybeSelectedItem<IPortfolioProject> =>
  state.aboutus.selectedProjectToEdit;

export default aboutUsSlice.reducer;
