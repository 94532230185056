import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../app/store';
import {
  Category,
  CategoryFilterType,
  CategoryName,
  FilterType,
  PillModel,
  PillProps,
  PillsState,
  PillState,
  QueryFilterType,
} from './Pill.types';

const initialState: PillsState = {
  pills: [
    {
      pillId: 'pill_0',
      label: 'Tech Pill',
      type: Category.Technology,
      refs: [],
    },
  ],
  loaded: false,
  loading: false,
  showFiltered: true,
  filterQuery: '',
  filterCategory: Category.Database,
};

export const pillsSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    onLoadCategories: (state: any, action: PayloadAction<any>) => {
      state.pills = action.payload.pills;
      state.loaded = true;
      state.loading = false;
    },
    onLoadingCategories: (state) => {
      state.loading = true;
    },
    onListReset: (state) => {
      state.pills = initialState.pills;
      state.loaded = false;
      state.loading = false;
      state.filterQuery = '';
      state.filterCategory = Category.Any;
    },
    setCategoryFilter: (state, action: PayloadAction<FilterType>) => {
      state.filterCategory = (action.payload as CategoryFilterType).category;
    },
    setQueryFilter: (state, action: PayloadAction<FilterType>) => {
      state.filterQuery = (action.payload as QueryFilterType).query;
    },
  },
});

export const { onLoadCategories, onLoadingCategories, onListReset, setCategoryFilter, setQueryFilter } =
  pillsSlice.actions;

export const loadPills = () => (dispatch: AppDispatch) => {
  const pillSkillsDummyData = [
    { type: 'Any', items: 'Any_1, Any_2, Any_3, Any_4, Any_5' },
    { type: 'Language', items: 'JavaScript, Java, C#, SQL, Scripting' },
    { type: 'VersionControl', items: 'Git, Mercurial, Perforce, SVN, CVS' },
    { type: 'Design', items: 'Photoshop, Illustrator, Sketch, Gimp, Visio' },
    { type: 'Database', items: 'PostgreSQL, MangoDB, Microsoft SQL Server, Apache Cassandra, Cloud Storage Solutions' },
    { type: 'Skill', items: 'Skill_1, Skill_2, Skill_3, Skill_4, Skill_5' },
    { type: 'Framework', items: 'React, Redux, React Native, Sencha EXTJS, Angular' },
    { type: 'Technology', items: 'Tech_1, Tech_2, Tech_3, Tech_4, Tech_5' },
    { type: 'School', items: 'UVic, First Aid, Douglas College, Camosun College' },
    { type: 'Web', items: 'HTML, CSS, SCSS, JSON, XML' },
    { type: 'OperatingSystem', items: 'Linux, Windows, macOS, iOS, Android' },
    { type: 'Software', items: 'Software_1, Software_2, Software_3, Software_4, Software_5' },
    { type: 'Error', items: 'ERROR_1, ERROR_2, ERROR_3, ERROR_4, ERROR_5' },
  ];

  let len: number = pillSkillsDummyData.length;
  const prefix = 'pill_';
  const dummyPillModels: PillModel[] = [];

  for (let i = 0; i < len; i++) {
    let cat = pillSkillsDummyData[i];
    let itemType = cat.type as CategoryName;
    let itemArray = cat.items.split(', ');
    const catArray = itemArray.map((label: string, index: number) => {
      let id = i * 5 + index;
      return {
        pillId: prefix.concat(id.toString()),
        label: label,
        type: Category[itemType],
        refs: ['C1'],
      };
    });
    dummyPillModels.push(...catArray);
  }

  setTimeout((...args) => {
    const payload: PillsState = {
      pills: dummyPillModels,
      loaded: true,
      loading: false,
      filterCategory: Category.Any,
      filterQuery: '',
      showFiltered: true,
    };

    dispatch(onLoadCategories(payload));
  }, 5000);

  dispatch(onLoadingCategories());
};

export const updateFilterQuery = (query: string) => (dispatch: AppDispatch) => {
  const payload: FilterType = { query };
  dispatch(setQueryFilter(payload));
};

export const updateFilterCategory = (filterCategory: string) => (dispatch: AppDispatch) => {
  const categoryType = filterCategory as CategoryName;
  const payload: FilterType = { category: Category[categoryType] };
  dispatch(setCategoryFilter(payload));
};

// Selectors

export const getFilterQuery = (state: RootState): string => state.categories.filterQuery;
export const getFilterCategory = (state: RootState): Category =>
  Category[state.categories.filterCategory as CategoryName];
const getAllCategories = (state: RootState): PillModel[] => state.categories.pills;
export const getCategoryFilterName = (state: RootState): string => state.categories.filterCategory as CategoryName;

export const isLoaded = (state: RootState): boolean => state.categories.loaded;
export const isLoading = (state: RootState): boolean => state.categories.loading;

const isHighlighted = (category: Category, query: string, pillCategory: Category, pillLabel: string): boolean => {
  let highlighted = false;

  if (category === Category.Any || category === pillCategory) {
    highlighted = pillLabel.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  }
  return highlighted;
};

export const getPillIds = (state: RootState): string[] => state.categories.pills.map((p: any) => p.pillId);

export const isFilterInUse = createSelector([getFilterCategory, getFilterQuery], (category, query) => {
  return !(category === Category.Any && query === '');
});

export const getPills = createSelector(
  [getAllCategories, getFilterCategory, getFilterQuery, isFilterInUse],
  (pills, category, query, isFilterOn): PillState[] => {
    return pills.map((model: PillModel): PillState => {
      return {
        pillId: model.pillId,
        label: model.label,
        type: model.type,
        highlighted: isFilterOn && isHighlighted(category, query, model.type, model.label),
        hidden: isFilterOn,
      };
    });
  }
);

export const getPillById = (state: RootState, itemId: string): PillState =>
  createSelector(getPills, (pills) => {
    const isFoundPill = (pill: PillProps) => pill.pillId === itemId;
    let foundIndex: number = pills.findIndex(isFoundPill);

    if (foundIndex !== -1) {
      return pills[foundIndex];
    }

    return emptyPill(itemId);
  })(state);

export const isPillHighlighted = (state: RootState, itemId: string): boolean =>
  createSelector(getPills, (pills) => {
    const isFoundPill = (pill: PillProps) => pill.pillId === itemId;
    let foundIndex: number = pills.findIndex(isFoundPill);

    if (foundIndex !== -1) {
      return pills[foundIndex].highlighted;
    }

    return false;
  })(state);

const emptyPill = (itemId: string): PillState => {
  return {
    pillId: itemId,
    label: 'NOT FOUND',
    highlighted: true,
    type: Category.Error,
    hidden: false,
  };
};

export default pillsSlice.reducer;
