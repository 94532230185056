import { db } from '../firebase/Firebase';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import {
  IEducation,
} from 'scenes/aboutus/AboutUs.types';

export const loadEducationData = async (portfolioId: string): Promise<Array<IEducation>> => {
  let educationItems: Array<IEducation> = [];
  const q = query(collection(db, `portfolio/${portfolioId}/education`), orderBy('toDate', 'desc'));
  const educationSnapshot = await getDocs(q);
  educationSnapshot.docs.forEach((doc) => {
    const docData = doc.data();
    educationItems.push({
      id: doc.id,
      name: docData.name,
      location: docData.location,
      description: docData.description ? docData.description.split('|') : ['No description'],
      fromDate: docData.fromDate?.toDate().toString(),
      toDate: docData.toDate?.toDate().toString(),
      logo: docData.logo,
      title: docData.title,
      skills: docData.skills,
      tags: docData.tags,
    });
  });
  return educationItems;
};

export const loadEducationDocument = async (portfolioId: string, educationId: string): Promise<IEducation> => {
  const docRef = doc(db, `portfolio/${portfolioId}/education/${educationId}`);
  const educationDocSnap = await getDoc(docRef);
  if (educationDocSnap.exists()) {
    let educationDoc: IEducation;
    const docData = educationDocSnap.data();
    educationDoc = {
      id: educationId,
      name: docData.name,
      location: docData.location,
      description: docData.description ? docData.description.split('|') : ['No description'],
      fromDate: docData.fromDate?.toDate().toString(),
      toDate: docData.toDate?.toDate().toString(),
      logo: docData.logo,
      title: docData.title,
      skills: docData.skills,
      tags: docData.tags,
    };

    return educationDoc;
  } else {
    console.error('No such document!');
    throw Error(`Education item does not exist for Path=portfolio/${portfolioId}/employment/${educationId}`);
  }
};

export const addSummaryItemToPortfolioEducation = async (
  portfolioId: string,
  categoryId: string,
  summaryItem: string,
  userUid: string
): Promise<IEducation> => {
  const summaryRef = doc(db, `portfolio/${portfolioId}/education/${categoryId}`);
  await updateDoc(summaryRef, {
    description: summaryItem,
    modifiedBy: userUid,
    modifiedAt: serverTimestamp(),
  });

  return loadEducationDocument(portfolioId, categoryId);
};
